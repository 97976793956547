import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';

import { useMeteringContext } from '@appUtils/context/MeteringContext';
import { useAircraftData } from '@appUtils/aircraft';
import { useFlightTimePrefill } from '@appUtils/hooks/metering/useFlightTimePrefill';
import Button from '@appComponents/Button';
import Dialog from '@appComponents/Dialog';
import Text from '@appComponents/Text';
import { useTheme } from '@appComponents/theme';
import { StyledIconButton } from '@appComponents/Metering/StyledIconButton';
import { Spacer, Box } from '@appComponents/ScreenLayout';
import { Title } from '@appComponents/Text';
import { FlightTimesModal } from './Modals/FlightTimesModal';

export const FlightTimes = ({ trip, handleSelectedLegChange }) => {
  const theme = useTheme();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getValues, saveMeteringData, selectedLeg, setSelectedLeg, setValue } =
    useMeteringContext();
  const legIndex = useMemo(() => {
    return selectedLeg?.index ?? -1;
  }, [selectedLeg?.index]);
  const updateMode = selectedLeg?.index === -1 ? 'trip' : 'leg';
  const prefillData = useFlightTimePrefill({ trip, getValues, legIndex });
  const [prefillPromptVisible, setPrefillPromptVisible] = useState(false);
  const [aircraft, aircraftLoading] = useAircraftData(trip?.aircraft?.path);

  const updatedMetering = trip?.metering;

  useEffect(() => {
    if (prefillData) {
      setPrefillPromptVisible(true);
    }
  }, [prefillData]);

  const applyPrefillData = useCallback(() => {
    const {
      hobbsEnding,
      airFrameEnding,
      engine1Ending,
      engine1CyclesEnding,
      engine2Ending,
      engine2CyclesEnding,
      engine3Ending,
      engine3CyclesEnding,
      landingEnding,
      apuEnding,
      apuCycleEnding,
    } = prefillData;
    if (hobbsEnding) {
      setValue('flightTimes.hobbsBeginning', parseFloat(hobbsEnding), {
        shouldValidate: true,
      });
    }
    if (airFrameEnding) {
      setValue('flightTimes.airFrameBeginning', parseFloat(airFrameEnding), {
        shouldValidate: true,
      });
    }
    if (engine1Ending) {
      setValue('flightTimes.engine1Beginning', parseFloat(engine1Ending), {
        shouldValidate: true,
      });
    }
    if (engine1CyclesEnding) {
      setValue(
        'flightTimes.engine1CyclesBeginning',
        parseFloat(engine1CyclesEnding),
        {
          shouldValidate: true,
        },
      );
    }
    if (engine2Ending) {
      setValue('flightTimes.engine2Beginning', parseFloat(engine2Ending), {
        shouldValidate: true,
      });
    }
    if (engine2CyclesEnding) {
      setValue(
        'flightTimes.engine2CyclesBeginning',
        parseFloat(engine2CyclesEnding),
        {
          shouldValidate: true,
        },
      );
    }
    if (engine3Ending) {
      setValue('flightTimes.engine3Beginning', parseFloat(engine3Ending), {
        shouldValidate: true,
      });
    }
    if (engine3CyclesEnding) {
      setValue(
        'flightTimes.engine3CyclesBeginning',
        parseFloat(engine3CyclesEnding),
        {
          shouldValidate: true,
        },
      );
    }
    if (landingEnding) {
      setValue('flightTimes.landingBeginning', parseFloat(landingEnding), {
        shouldValidate: true,
      });
    }
    if (apuEnding) {
      setValue('flightTimes.apuBeginning', parseFloat(apuEnding), {
        shouldValidate: true,
      });
    }
    if (apuCycleEnding) {
      setValue('flightTimes.apuCycleBeginning', parseFloat(apuCycleEnding), {
        shouldValidate: true,
      });
    }
    saveMeteringData(true);
    setPrefillPromptVisible(false);
  }, [prefillData, saveMeteringData, setValue]);

  if (aircraftLoading) {
    return null;
  }

  if (aircraft?.tailNumber === undefined) {
    return (
      <>
        <Spacer size={3} />
        <Box dir="row" jc="center">
          <Title>No Aircraft Selected for this Trip</Title>
        </Box>
      </>
    );
  }

  const isHobbs = aircraft?.meteringSystem
    ? aircraft?.meteringSystem === 'Hobbs'
    : true;
  const showAPU = ['Hobbs', 'FMS'].includes(aircraft?.apuMeteringSystem);

  const getFlightTimeData = (field, index) => {
    if (updateMode === 'leg' && index > -1) {
      return updatedMetering?.legs?.[index]?.flightTimes?.[field] || '-';
    }

    return updatedMetering?.overall?.flightTimes?.[field] || '-';
  };

  const openModal = leg => {
    setSelectedLeg(leg);
    if (leg?.index > -1) {
      handleSelectedLegChange(leg);
    }
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    const newLeg =
      selectedLeg?.index === -1
        ? { index: -1, value: -1, id: -1 }
        : { index: 0, value: 0, id: trip?.legs?.[0]?.id ?? 0 };
    setSelectedLeg(newLeg);
    if (newLeg.index > -1) {
      handleSelectedLegChange(newLeg);
    }
  };

  const renderMainInfo = (leg, index) => {
    // Labels for the first column
    const labels = [
      { label: 'Beginning', fieldKey: 'Beginning' },
      {
        label: isHobbs ? 'Ending' : 'Current',
        fieldKey: isHobbs ? 'Ending' : 'Current',
      },
      {
        label: isHobbs ? 'Current' : 'Ending',
        fieldKey: isHobbs ? 'Current' : 'Ending',
      },
    ];

    const sections = [];

    if (isHobbs) {
      sections.push({
        sectionTitle: 'HOBBS (HR)',
        dataKeys: {
          Beginning: 'hobbsBeginning',
          Ending: 'hobbsEnding',
          Current: 'hobbsCurrent',
        },
      });
    }

    sections.push({
      sectionTitle: 'AIR FRAME (HR)',
      dataKeys: {
        Beginning: 'airFrameBeginning',
        Ending: 'airFrameEnding',
        Current: 'airFrameCurrent',
      },
    });

    // For each engine
    for (let i = 0; i < aircraft?.numEngines; i++) {
      const engineNumber = i + 1;
      sections.push({
        sectionTitle: `ENGINE ${engineNumber} (HR)`,
        dataKeys: {
          Beginning: `engine${engineNumber}Beginning`,
          Ending: `engine${engineNumber}Ending`,
          Current: `engine${engineNumber}Current`,
        },
      });
      sections.push({
        sectionTitle: `ENGINE ${engineNumber} CYCLES`,
        dataKeys: {
          Beginning: `engine${engineNumber}CyclesBeginning`,
          Ending: `engine${engineNumber}CyclesEnding`,
          Current: `engine${engineNumber}CyclesCurrent`,
        },
      });
    }

    // LANDING section
    sections.push({
      sectionTitle: 'LANDINGS',
      dataKeys: {
        Beginning: 'landingBeginning',
        Ending: 'landingEnding',
        Current: 'landingCurrent',
      },
    });

    // APU sections if applicable
    if (showAPU) {
      sections.push({
        sectionTitle: 'APU (HR)',
        dataKeys: {
          Beginning: 'apuBeginning',
          Ending: 'apuEnding',
          Current: 'apuCurrent',
        },
      });
      sections.push({
        sectionTitle: 'APU CYCLES',
        dataKeys: {
          Beginning: 'apuCycleBeginning',
          Ending: 'apuCycleEnding',
          Current: 'apuCycleCurrent',
        },
      });
    }

    // Adjust sections into rows of 3 columns (excluding the labels column)
    const columnsPerRow = 3;
    const sectionRows = [];
    for (let i = 0; i < sections.length; i += columnsPerRow) {
      sectionRows.push(sections.slice(i, i + columnsPerRow));
    }

    return (
      <View style={styles.infoContainer}>
        {updateMode === 'leg' ? (
          <Text style={[styles.legHeader, { color: theme.colors.text }]}>
            {leg?.from} &gt; {leg?.to}
          </Text>
        ) : null}
        {sectionRows.map((sectionRow, rowIndex) => (
          <View key={rowIndex} style={styles.rowContainer}>
            <View style={styles.labelColumn}>
              <Text
                style={[
                  styles.sectionTitle,
                  { color: theme.colors.tableBackground },
                ]}>
                placeholder
              </Text>
              {labels.map(labelItem => (
                <View
                  key={labelItem.fieldKey}
                  style={[
                    styles.cell,
                    { backgroundColor: theme.colors.background },
                  ]}>
                  <Text
                    style={[{ color: theme.colors.text }, styles.labelCell]}>
                    {labelItem.label}
                  </Text>
                </View>
              ))}
            </View>
            {sectionRow.map(section => (
              <View key={section.sectionTitle} style={styles.valueColumn}>
                <Text
                  style={[
                    styles.sectionTitle,
                    { color: theme.colors.text },
                    styles.textCenter,
                  ]}>
                  {section.sectionTitle}
                </Text>
                {labels.map(labelItem => (
                  <View
                    key={labelItem.fieldKey}
                    style={[
                      styles.cell,
                      { backgroundColor: theme.colors.background },
                    ]}>
                    <Text
                      style={[{ color: theme.colors.text }, styles.textCenter]}>
                      {getFlightTimeData(
                        section.dataKeys[labelItem.fieldKey],
                        index,
                      ) ?? '-'}
                    </Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        ))}
      </View>
    );
  };

  return (
    <>
      <ScrollView style={styles.container}>
        {updateMode === 'leg' ? (
          trip.legs.map((leg, index) => (
            <View key={leg.id}>
              <View
                style={[
                  styles.legHeaderContainer,
                  { borderBottomColor: theme.colors.white },
                ]}>
                <Text
                  style={[styles.legIndexText, { color: theme.colors.text }]}>
                  Leg {index + 1}
                </Text>
                <StyledIconButton
                  icon="edit"
                  backgroundColor={theme.colors.primary}
                  onPress={() => {
                    setSelectedLeg({
                      index,
                    });
                    openModal({ ...leg, index });
                  }}
                />
              </View>
              {renderMainInfo(leg, index)}
            </View>
          ))
        ) : (
          <View>
            <View
              style={[
                styles.legHeaderContainer,
                { borderBottomColor: theme.colors.white },
              ]}>
              <StyledIconButton
                icon="edit"
                backgroundColor={theme.colors.primary}
                onPress={() => {
                  openModal(null);
                }}
                hasMargin
              />
            </View>
            {renderMainInfo(null)}
          </View>
        )}
      </ScrollView>
      {prefillPromptVisible && (
        <PrefillPrompt
          updateMode={updateMode}
          prefillData={prefillData}
          setPrefillPromptVisible={setPrefillPromptVisible}
          onAccept={applyPrefillData}
        />
      )}

      {isModalVisible && !prefillPromptVisible ? (
        <FlightTimesModal
          onClose={closeModal}
          trip={trip}
          aircraft={aircraft}
          isHobbs={isHobbs}
          showAPU={showAPU}
          updateMode={updateMode}
        />
      ) : null}
    </>
  );
};

const PrefillPrompt = ({
  updateMode,
  prefillData,
  setPrefillPromptVisible,
  onAccept,
}) => {
  const endingValue = parseFloat(prefillData?.airFrameEnding);
  if (!endingValue) {
    setPrefillPromptVisible(false);
    return null;
  }
  const message = `Flight Time data from a previous ${updateMode} is available. Do you want to use the ending values from that ${updateMode} as the beginning values for this ${updateMode}?
It would start this ${updateMode} with an airframe time of ${endingValue?.toFixed(
    1,
  )} hours.`;

  return (
    <Dialog
      visible
      title="Previous Flight Time Data Available"
      actionSlot={
        <>
          <Button
            mode="outlined"
            color="error"
            onPress={() => setPrefillPromptVisible(false)}>
            Cancel
          </Button>
          <Button mode="outlined" onPress={onAccept} ml={1}>
            Use Previous Data
          </Button>
        </>
      }>
      <Text>{message}</Text>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  legHeaderContainer: {
    borderBottomWidth: 1,
    padding: 12,
    marginTop: 22,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  legIndexText: {
    fontSize: 18,
  },
  infoContainer: {
    marginVertical: 16,
    paddingHorizontal: 12,
  },
  legHeader: {
    fontSize: 18,
    marginBottom: 16,
    marginTop: 16,
  },
  rowContainer: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  labelColumn: {
    width: '18%',
  },
  valueColumn: {
    width: '25%',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
  cell: {
    paddingVertical: 14,
    paddingHorizontal: 4,
    marginVertical: 2,
  },
  textCenter: {
    textAlign: 'center',
  },
  labelCell: {
    paddingLeft: 6,
  },
});
