/**
 * @file Message related functions
 */
import app, {
  FieldValue,
  getUid,
  useCollection,
  DocumentData,
} from '@appFirebase';
import { useMyCompanyRef, useList, getMyCompanyRef, getMyUserDoc } from './api';
import { useCallback } from 'react';
import _ from 'lodash';
import tripConverter, { UserRole } from './tripConverter';

export const ConversationTag = {
  ALL: 'All',
  COMPANY: 'Manager & Pilot',
};

export const useCompanyConversations = () => {
  const queryCallback = useCallback(companyRef => {
    return companyRef.collection('conversations');
  }, []);

  return useList(queryCallback, conversationConverter);
};

export const getConversationTrip = async (conversationId = '') => {
  const companyRef = await getMyCompanyRef();
  if (_.isEmpty(companyRef)) {
    return {};
  }
  const conversation = await companyRef
    .collection('conversations')
    .doc(conversationId)
    .get();
  const conversationData = conversation.data();
  if (_.isEmpty(conversation)) {
    return {};
  }
  const trip = await companyRef
    .collection('trips')
    .doc(conversationData?.tripId)
    .get();
  return tripConverter.fromFirestore(trip);
};

export const useTripConversation = (tripId = '') => {
  const [companyRef] = useMyCompanyRef();

  let query;
  if (companyRef) {
    query = companyRef
      .collection('conversations')
      .where('tripId', '==', tripId);
  }
  const [snapshots] = useCollection(query);
  if (snapshots?.docs[0]) {
    return conversationConverter.fromFirestore(snapshots.docs[0]);
  }
};

export const useUserMessages = () => {
  const uid = getUid();
  const queryCallback = useCallback(
    companyRef => {
      return companyRef
        .collection('conversations')
        .where('participantIds', 'array-contains', uid);
    },
    [uid],
  );

  return useList(queryCallback, conversationConverter);
};

export const useUnreadUserMessages = () => {
  const uid = getUid();
  const queryCallback = useCallback(
    companyRef => {
      return companyRef
        .collection('conversations')
        .where(`participants.${uid}.unreadMessages`, '>', 0);
    },
    [uid],
  );

  return useList(queryCallback, conversationConverter);
};

export const getUnreadUserMessages = async () => {
  const uid = getUid();
  const companyRef = await getMyCompanyRef();
  const snapshots = await companyRef
    .collection('conversations')
    .where(`participants.${uid}.unreadMessages`, '>', 0)
    .get();
  if (!snapshots.empty) {
    const unreadMessages = snapshots.docs.map(doc => doc.data());
    return unreadMessages;
  }
  return [];
};

export const useUnreadCompanyMessages = (archived = false) => {
  const queryCallback = useCallback(
    companyRef => {
      return companyRef
        .collection('conversations')
        .where('archived', '==', archived)
        .where('managementUnreadMessages', '>', 0);
    },
    [archived],
  );

  return useList(queryCallback, conversationConverter);
};

export const useUnreadCompanyMessagesCount = (archived = false) => {
  const { data: unreadMessages } = useUnreadCompanyMessages(archived);
  return _.sumBy(
    unreadMessages,
    conversation => conversation.managementUnreadMessages,
  );
};

export const sendMessage = async (conversationId, text, trip) => {
  const user = await getMyUserDoc().get();
  const userData = user.data();
  const companyRef = await getMyCompanyRef();
  const conversationRef = companyRef
    .collection('conversations')
    .doc(conversationId);
  const newMessage = {
    author: {
      name: `${userData?.firstName} ${userData?.lastName}`,
      id: user?.id,
      role: userData?.role,
    },
    dateSent: FieldValue.serverTimestamp(),
    text,
  };

  app.firestore().runTransaction(async t => {
    const doc = await t.get(conversationRef);
    const conversation = doc.data();
    const nextMessageIndex = conversation?.nextMessageIndex ?? 0;
    const messages = {
      ...conversation?.messages,
      [nextMessageIndex]: newMessage,
    };
    let updatedConversation = {
      nextMessageIndex: nextMessageIndex + 1,
      messages,
    };
    const isManager =
      userData.role === UserRole.MANAGER || userData.role === UserRole.EMPLOYEE;

    if (!conversationId) {
      updatedConversation = setupNewConversation({
        updatedConversation,
        trip,
        user,
        nextMessageIndex,
        companyRef,
        isManager,
      });
    } else if (isManager) {
      updatedConversation = updateConversationForManagerMessage({
        updatedConversation,
        nextMessageIndex,
        conversation,
      });
      updatedConversation =
        conversationConverter.toFirestore(updatedConversation);
    } else {
      updatedConversation = updateConversationForMobileMessage({
        updatedConversation,
        nextMessageIndex,
        conversation,
        user,
      });
      updatedConversation =
        conversationConverter.toFirestore(updatedConversation);
    }

    t.set(conversationRef, updatedConversation, { merge: true });
  });
};

const setupNewConversation = ({
  updatedConversation,
  trip,
  user,
  nextMessageIndex,
  companyRef,
  isManager,
}) => {
  const { participants, participantIds } = getParticipants({
    trip,
    authorId: user?.id,
    currentMessageIndex: nextMessageIndex + 1,
  });
  updatedConversation.dateCreated = FieldValue.serverTimestamp();
  updatedConversation.participants = participants;
  updatedConversation.participantIds = participantIds;
  updatedConversation.tripId = trip.id;
  updatedConversation.tripRef = companyRef.collection('trips').doc(trip.id);
  updatedConversation.tag = ConversationTag.ALL;
  updatedConversation.managementLastReadIndex = isManager ? 0 : -1;
  updatedConversation.managementUnreadMessages = isManager ? 0 : 1;
  updatedConversation.archived = trip.archived ?? false;
  return updatedConversation;
};

const updateConversationForManagerMessage = ({
  updatedConversation,
  nextMessageIndex,
  conversation,
}) => {
  updatedConversation.managementLastReadIndex = nextMessageIndex;
  updatedConversation.managementUnreadMessages = 0;
  updatedConversation.participants = updateUnreadMessages({
    conversation,
    nextMessageIndex,
  });
  return updatedConversation;
};

const updateConversationForMobileMessage = ({
  updatedConversation,
  nextMessageIndex,
  conversation,
  user,
}) => {
  updatedConversation.managementUnreadMessages =
    nextMessageIndex - conversation.managementLastReadIndex;
  const currentParticipant = conversation?.participants[user?.id];
  currentParticipant.lastReadIndex = nextMessageIndex;
  updatedConversation.participants = updateUnreadMessages({
    conversation,
    nextMessageIndex,
  });
  return updatedConversation;
};

const updateUnreadMessages = ({ conversation, nextMessageIndex }) =>
  Object.assign(
    ..._.map(conversation.participants, participant => {
      return {
        [participant.id]: {
          ...participant,
          unreadMessages: nextMessageIndex - participant.lastReadIndex,
        },
      };
    }),
  );

export const clearUserUnreadMessages = ({ conversation }) => {
  const uid = getUid();
  const currentParticipant = conversation?.participants[uid];
  if (!currentParticipant) {
    return;
  }
  currentParticipant.lastReadIndex = conversation?.nextMessageIndex - 1;
  currentParticipant.unreadMessages = 0;
  app.firestore().runTransaction(async t => {
    t.update(conversation.ref, {
      participants: conversation?.participants,
    });
  });
};

export const clearManagerUnreadMessages = ({ conversation }) => {
  conversation.managementLastReadIndex = conversation?.nextMessageIndex - 1;
  conversation.managementUnreadMessages = 0;
  app.firestore().runTransaction(async t => {
    t.update(conversation.ref, conversation);
  });
};

export const updateConversationParticipants = ({ conversation, trip }) => {
  const participants = conversation?.participants;
  const participantIds = conversation?.participantIds;

  const { participants: newParticipants, participantIds: newParticipantIds } =
    getParticipants({
      trip,
      currentMessageIndex: conversation?.nextMessageIndex ?? 0,
    });
  if (_.isEqual(participantIds, newParticipantIds)) {
    return;
  }
  const finalParticipants = Object.assign(
    ..._.map(newParticipants, newParticipant => {
      if (participants[newParticipant.id]) {
        return { [newParticipant.id]: participants[newParticipant.id] };
      }
      return { [newParticipant.id]: newParticipant };
    }),
  );
  app.firestore().runTransaction(async t => {
    t.update(conversation.ref, {
      participants: finalParticipants,
      participantIds: newParticipantIds,
    });
  });
};

const getParticipants = ({ trip, authorId, currentMessageIndex }) => {
  let participants = {};
  const participantIds = new Set();
  const ownerId = trip?.owner?.id;

  if (ownerId) {
    participantIds.add(ownerId);
    participants[ownerId] = {
      id: ownerId,
      name: `${trip.owner.firstName} ${trip.owner.lastName}`,
      lastReadIndex: authorId === ownerId ? 0 : -1,
      unreadMessages: authorId === ownerId ? 0 : currentMessageIndex,
    };
  }

  _.forEach(trip.pilots ?? [], pilot => {
    const pilotId = pilot.id;

    participantIds.add(pilotId);
    participants[pilotId] = {
      id: pilotId,
      name: `${pilot.firstName} ${pilot.lastName}`,
      lastReadIndex: authorId === pilotId ? 0 : -1,
      unreadMessages: authorId === pilotId ? 0 : currentMessageIndex,
    };
  });

  trip?.legs?.map(l => {
    l?.passengers?.map(p => {
      if (p?.id) {
        const passengerId = p.id;
        participantIds.add(passengerId);
        if (!participants[passengerId]) {
          participants[passengerId] = {
            id: passengerId,
            name: `${p.firstName} ${p.lastName}`,
            lastReadIndex: authorId === passengerId ? 0 : -1,
            unreadMessages: authorId === passengerId ? 0 : currentMessageIndex,
          };
        }
      }
    });
  });

  return { participantIds: Array.from(participantIds), participants };
};

export type Conversation = {
  archived: boolean,
  dateCreated: DateTime,
  managementLastReadIndex: Number,
  managementUnreadMessages: Number,
  messages: Array<{
    author: { id: string, name: string, role: string },
    dateSent: DateTime,
    text: string,
  }>,
  nextMessageIndex: Number,
  participantIds: Array<string>,
  participants: {
    string: {
      id: string,
      lastReadIndex: Number,
      name: string,
      unreadMessages: Number,
    },
  },
  tag: string,
  tripId: string,
  tripRef: string,
  id: string,
  path: string,
  ref: string,
};

const conversationConverter = {
  toFirestore(conversation: Conversation): DocumentData {
    /**
     * IMPORTANT
     * `toFirestore` should be able to work with partial data, it would not always receive a full conversation
     * derived fields should only be added if the data they are derived from is present
     * This allows us to send just the information that needs to update (delta) and helps with change tracking
     */
    return _.omitBy(
      conversation,
      // Ignore undefined values or keys that shouldn't be modified or included
      (value, key) =>
        _.isUndefined(value) ||
        _.includes(['dateCreated', 'path', 'id', 'ref'], key),
    );
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Conversation {
    const data = snapshot.data(options);
    return {
      ...data,
      ref: snapshot.ref,
      path: snapshot.ref.path,
      id: snapshot.ref.id,
    };
  },
};
