/**
 * @file
 * User (Pilot/Owner/Passenger) management page
 *
 * @format
 * @flow strict-local
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/native';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import { FitToContentButton } from '@appComponents/Button';
import { AddButton } from '@appComponents/List';
import {
  ScreenLoader,
  SectionHeader,
  Spacer,
  Box,
} from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { useTheme } from '@appComponents/theme';
import {
  FirstNameFormField,
  LastNameFormField,
  PhoneNumberFormField,
  NumberField,
} from '@appComponents/forms/FormFields';
import {
  useCompanyUsers,
  getUserData,
  archiveOrDeleteUser,
} from '@appUtils/api';
import { useUserAircraft } from '@appUtils/aircraft';
import { userToPassengerData } from '@appUtils/passengers';
import {
  saveUserDocuments,
  saveUserOwnerIds,
  saveOwnerPassengerIds,
  useUserData,
  updateOtherUser,
} from '@appUtils/user';
import { UserRole } from '@appUtils/tripConverter';
import { getRoleString } from '@appUtils/user';
import SelectOwner from '@webComponents/SelectOwner';
import { PhoneCell, PlainCell, UsersTable } from '@webComponents/TableCells';
import { EnterToSubmit } from '@webComponents/EnterToSubmit';

import ArchiveDialog from './ArchiveDialog';
import EditCell from './EditCell';
import Documents from './Documents';

type UserProps = {
  userId: string,
  role: string,
  from: Object,
  children: React$Node,
};

const UserDetails = ({ userId, role, from, children }: UserProps) => {
  const [user, loading] = useUserData(userId);
  const [owners, ownersLoading] = useCompanyUsers(UserRole.OWNER);

  if (!loading && !ownersLoading) {
    return (
      <Wrapper>
        <Details
          user={user}
          role={role}
          owners={owners}
          hasFrom={Boolean(from)}>
          {children}
        </Details>
      </Wrapper>
    );
  }

  return <ScreenLoader />;
};

const Details = ({ user, role, owners, hasFrom, children }) => {
  const saveDocuments = useSaveDocumentsCallback(user.id);

  return (
    <Wrapper>
      <SectionWrapper>
        <ContactDetails user={user} role={role} />
        {role === UserRole.PASSENGER && (
          <PassengerOwners
            user={user}
            owners={owners?.map(o => userToPassengerData({ user: o }))}
            hasFrom={hasFrom}
          />
        )}
        <Documents
          user={user}
          saveDocuments={saveDocuments}
          sectionTitle={`${role} DOCUMENTS`.toUpperCase()}
        />
      </SectionWrapper>
      {!_.isEmpty(children) && (
        <>
          <Spacer size={2} />
          <SectionWrapper>{children}</SectionWrapper>
        </>
      )}
    </Wrapper>
  );
};

const ContactDetails = ({ user, role = 'user' }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [isArchiving, setIsArchiving] = useState(false);
  const [archiveMode, setArchiveMode] = useState('archive'); // 'archive' | 'delete'
  const { data: aircraft = [] } = useUserAircraft({
    id: user?.id,
    role: user?.role,
  });

  const onArchive = () => {
    setIsArchiving(true);
    if (user?.archived) {
      setArchiveMode('delete');
    }
  };

  const onConfirmArchive = () => {
    try {
      setIsArchiving(false);
      archiveOrDeleteUser(user, aircraft);
    } catch (error) {
      console.error('Error archiving user', error);
    }
  };

  const onCancelArchive = () => setIsArchiving(false);

  const defaultValues = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    weight: user?.weight ?? '',
  };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({ defaultValues });
  const update = handleSubmit(
    useCallback(
      async payload => {
        if (isDirty) {
          updateOtherUser({
            ...payload,
            uid: user.id,
          });
        }
        setIsEditing(false);
      },
      [isDirty, user.id],
    ),
  );

  return (
    <>
      <SectionHeader>
        <Text color="dark" weight={500}>
          {`${getRoleString(
            role === UserRole.EMPLOYEE ? UserRole.MANAGER : role,
          )} DETAILS`.toUpperCase()}
        </Text>
      </SectionHeader>
      {isEditing ? (
        <>
          <EnterToSubmit onSubmit={update} />
          <Spacer />
          <EditCell
            numeric
            name="edit"
            onEdit={() => setIsEditing(true)}
            editMode={isEditing}
            flex={2}
            onArchive={onArchive}
            onSave={update}
          />
          <Box dir="row" jc="space-around">
            <HalfWidthWrapper>
              <FirstNameFormField label="First name" control={control} />
            </HalfWidthWrapper>
            <HalfWidthWrapper>
              <LastNameFormField label="Last name" control={control} />
            </HalfWidthWrapper>
          </Box>
          <Box dir="row" jc="space-around">
            <HalfWidthWrapper>
              <PhoneNumberFormField
                label="Phone"
                placeholder="Phone number"
                control={control}
                optional={true}
              />
            </HalfWidthWrapper>
            <HalfWidthWrapper>
              <NumberField
                light={false}
                labelCol={false}
                decimalScale={0}
                label="Weight (lbs)"
                name="weight"
                placeholder="Weight (lbs)"
                control={control}
                optional={true}
              />
            </HalfWidthWrapper>
          </Box>
        </>
      ) : (
        <UsersTable list={[user]} listType={role}>
          <PlainCell title="First Name" path="firstName" flex={1} />
          <PlainCell title="Last Name" path="lastName" flex={1} />
          <PlainCell title="Email" path="email" flex={1} />
          <PhoneCell title="Phone" flex={1} />
          <PlainCell title="Weight" path="weight" flex={1} />
          <EditCell
            numeric
            name="edit"
            onEdit={() => setIsEditing(true)}
            editMode={isEditing}
            flex={2}
            onArchive={onArchive}
            onSave={update}
          />
        </UsersTable>
      )}

      <ArchiveDialog
        show={isArchiving}
        archiveMode={archiveMode}
        onCancel={onCancelArchive}
        onConfirm={onConfirmArchive}
      />
    </>
  );
};

const HalfWidthWrapper = styled.View`
  width: 48%;
`;

/**
 * Assign the owners associated with the current app passenger
 */
const PassengerOwners = ({ user, owners, hasFrom }) => {
  const ownerRef = useRef(null);
  const userId = user.id;
  const [userOwners, setUserOwners] = useState([]);
  const theme = useTheme();
  const fieldStyle = useOwnerFieldStyle();
  useEffect(() => {
    const uOwners =
      user?.ownerIds?.map(uId => _.find(owners, o => o.id === uId)) ?? [];
    if (!_.isEqual(uOwners, userOwners)) {
      setUserOwners(uOwners);
    }
  }, [owners, user?.ownerIds, userOwners]);

  const userOwnerIds = useMemo(() => user?.ownerIds ?? [], [user?.ownerIds]);
  const [addingOwner, setAddingOwner] = useState(false);
  const { addOwner, replaceOwner, removeOwner } = useHandleOwnerChanges({
    userId,
    owners: userOwners,
    setAddingOwner,
  });

  useEffect(() => {
    // useState(hasFrom) doesn't trigger owner focus like it should so check it here instead
    if (hasFrom) {
      setAddingOwner(true);
    }
  }, [hasFrom]);

  useEffect(() => {
    if (addingOwner) {
      ownerRef?.current?.focus();
    }
  }, [addingOwner]);

  return (
    <>
      <SectionHeader>
        <Text color="dark" weight={500}>
          Clients
        </Text>
      </SectionHeader>
      <OwnerRows
        userOwners={userOwners}
        replaceOwner={replaceOwner}
        removeOwner={removeOwner}
        userOwnerIds={userOwnerIds}
        theme={theme}
        fieldStyle={fieldStyle}
      />
      <Spacer />
      {!addingOwner && (
        <AddButton onPress={() => setAddingOwner(true)}>
          Assign Additional Client
        </AddButton>
      )}
      {addingOwner && (
        <Row>
          <PassengerOwner
            index={userOwners.length}
            save={addOwner}
            remove={() => {
              setAddingOwner(false);
            }}
            ownerIds={userOwnerIds}
            theme={theme}
            fieldStyle={fieldStyle}
            innerRef={ownerRef}
          />
        </Row>
      )}
    </>
  );
};

const PassengerOwner = ({
  index,
  owner,
  ownerIds,
  theme,
  fieldStyle,
  save,
  remove,
  innerRef,
}) => {
  const ownerRef = useRef(null);

  return (
    <OwnerWrapper>
      <SelectOwner
        key={`${index}${owner?.id}`}
        owner={owner}
        onChange={change => {
          save({ change, index });
        }}
        style={fieldStyle}
        controlStyle={{
          backgroundColor: theme.colors.background,
          color: theme.colors.text,
          borderColor: theme.colors.fieldBorder,
        }}
        isOptionDisabled={o => ownerIds?.includes(o.id)}
        innerRef={innerRef ?? ownerRef}
      />
      <FitToContentButton
        color={'secondary'}
        icon={'bin'}
        onPress={() => {
          remove({ index });
        }}
        aSelf="center"
        mr="2"
      />
    </OwnerWrapper>
  );
};

const OWNERS_PER_ROW = 3;

const OwnerWrapper = styled.View`
  flex-direction: row;
  width: 33%;
`;

const OwnerRows = ({
  userOwners,
  replaceOwner,
  removeOwner,
  userOwnerIds,
  theme,
  fieldStyle,
}) => {
  const rows = [];
  const numRows = 1 + Math.round(userOwners.length / OWNERS_PER_ROW);
  for (let i = 0; i < numRows; i++) {
    const currentIndex = OWNERS_PER_ROW * i;
    const row = [];
    for (let j = 0; j < OWNERS_PER_ROW; j++) {
      if (userOwners[currentIndex + j]) {
        row.push(
          <PassengerOwner
            index={currentIndex + j}
            owner={userOwners[currentIndex + j]}
            save={replaceOwner}
            remove={removeOwner}
            ownerIds={userOwnerIds}
            theme={theme}
            fieldStyle={fieldStyle}
          />,
        );
      }
    }
    rows.push(<Row>{row}</Row>);
  }
  return rows;
};

const Row = styled.View`
  flex-direction: row;
  margin-top: 20;
`;

const useOwnerFieldStyle = () => {
  const theme = useTheme();
  return {
    marginRight: theme.layout.space(1),
    width: '100%',
  };
};

const useHandleOwnerChanges = ({ userId, owners, setAddingOwner }) => {
  const addOwner = useCallback(
    ({ change }) => {
      const ownerIds = owners?.map(o => o.id) ?? [];
      ownerIds.push(change.id);
      saveUserOwnerIds(userId, ownerIds);
      setAddingOwner(false);
      getUserData(change.id).then(owner => addPassengerId({ owner, userId }));
    },
    [owners, setAddingOwner, userId],
  );

  const replaceOwner = useCallback(
    ({ change, index }) => {
      const ownerIds = owners?.map(o => o.id) ?? [];
      const oldOwnerId = ownerIds[index];
      ownerIds[index] = change.id;
      saveUserOwnerIds(userId, ownerIds);
      getUserData(change.id).then(owner => addPassengerId({ owner, userId }));
      getUserData(oldOwnerId).then(owner =>
        removePassengerId({ owner, userId }),
      );
    },
    [owners, userId],
  );

  const removeOwner = useCallback(
    ({ index }) => {
      const ownerIds = owners?.map(o => o.id) ?? [];
      const ownerId = ownerIds[index];
      ownerIds.splice(index, 1);
      saveUserOwnerIds(userId, ownerIds);
      getUserData(ownerId).then(owner => removePassengerId({ owner, userId }));
    },
    [owners, userId],
  );

  return { addOwner, replaceOwner, removeOwner };
};

const addPassengerId = ({ owner, userId }) => {
  const passengerIds = owner?.passengerIds ?? [];
  passengerIds.push(userId);
  saveOwnerPassengerIds({ ownerId: owner.id, passengerIds });
};

const removePassengerId = ({ owner, userId }) => {
  let passengerIds = owner?.passengerIds ?? [];
  _.remove(passengerIds, i => i === userId);
  saveOwnerPassengerIds({ ownerId: owner.id, passengerIds });
};

const useSaveDocumentsCallback = userId => {
  return useCallback(
    documents => saveUserDocuments(userId, documents),
    [userId],
  );
};

const Wrapper = styled.ScrollView(({ theme }) => ({
  flex: 1,
  paddingVertical: theme.layout.space(2),
  paddingHorizontal: theme.layout.space(2),
}));

const SectionWrapper = styled.ScrollView(({ theme }) => ({
  paddingVertical: theme.layout.space(2),
  paddingHorizontal: theme.layout.space(2),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colors.white,
  borderRadius: theme.layout.space(1),
}));

export default UserDetails;
