import { useEffect } from 'react';

export const EnterToSubmit = ({ onSubmit }) => {
  useEffect(() => {
    const keyHandler = event => {
      const key = event.key;
      if (key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };
    document.addEventListener('keydown', keyHandler, true);

    return () => {
      document.removeEventListener('keydown', keyHandler, true);
    };
  });

  return null;
};
