import { useCallback, useMemo, useState } from 'react';
import { isEmpty, last } from 'lodash';

import { useAircraftData } from '@appUtils/aircraft';
import { getUserData } from '@appUtils/api';
import { TripState } from '@appUtils/tripConverter';

// TODO: Figure out why useCycleIncrement is incrementing landingCurrent for Hobbs
const HobbsAutoIncrementFields = ['landingCurrent'];
const FMSAutoIncrementFields = [
  'engine1CyclesCurrent',
  'engine1CyclesEnding',
  'engine2CyclesCurrent',
  'engine2CyclesEnding',
  'engine3CyclesCurrent',
  'engine3CyclesEnding',
  'landingCurrent',
  'landingEnding',
];
const HobbsAPUAutoIncrementFields = [];
const FMSAPUAutoIncrementFields = ['apuCycleCurrent', 'apuCycleEnding'];

const getAutoIncrementedFields = ({ isHobbs, isAPUHobbs }) => {
  if (isHobbs && isAPUHobbs) {
    return [...HobbsAutoIncrementFields, ...HobbsAPUAutoIncrementFields];
  }
  if (isHobbs && !isAPUHobbs) {
    return [...HobbsAutoIncrementFields, ...FMSAPUAutoIncrementFields];
  }
  if (!isHobbs && !isAPUHobbs) {
    return [...FMSAutoIncrementFields, ...FMSAPUAutoIncrementFields];
  }
  if (!isHobbs && isAPUHobbs) {
    return [...FMSAutoIncrementFields, ...HobbsAPUAutoIncrementFields];
  }
  return [];
};

export const useFlightTimePrefill = ({ trip, getValues, legIndex }) => {
  const [prefillData, setPrefillData] = useState();

  const [aircraft, aircraftLoading] = useAircraftData(trip?.aircraft?.path);
  const isHobbs = aircraft?.meteringSystem
    ? aircraft?.meteringSystem === 'Hobbs'
    : true;
  const isAPUHobbs = aircraft?.apuMeteringSystem === 'Hobbs';

  const currentData = getValues();
  const autoIncrementedFields = useMemo(
    () => getAutoIncrementedFields({ isHobbs, isAPUHobbs }),
    [isAPUHobbs, isHobbs],
  );

  const hasData = useMemo(() => {
    if (!aircraftLoading) {
      const keys = Object.keys(currentData?.flightTimes ?? {});
      let data = false;
      keys.forEach(k => {
        const value = currentData.flightTimes[k];
        if (value !== undefined) {
          if (
            !autoIncrementedFields.includes(k) ||
            (autoIncrementedFields.includes(k) && legIndex > -1
              ? Number(value) !== 1
              : Number(value) !== trip.legs.length)
          ) {
            data = true;
          }
        }
      });
      return data;
    }
  }, [
    aircraftLoading,
    autoIncrementedFields,
    currentData.flightTimes,
    legIndex,
    trip.legs.length,
  ]);

  const fetchPrefillData = useCallback(async () => {
    if (isEmpty(trip) || !trip.aircraft?.tailNumber || !trip.dateDeparting) {
      return;
    }

    if (legIndex > 0) {
      // fill from previous leg in the same trip
      setPrefillData(trip?.metering?.legs?.[legIndex - 1]?.flightTimes ?? {});
    } else {
      // fill from previous trip
      try {
        const userData = await getUserData();
        const companyRef = userData.managementCompany?.ref;

        if (!companyRef) {
          return;
        }

        const simpleQuery = companyRef
          .collection('trips')
          .where('aircraft.tailNumber', '==', trip.aircraft.tailNumber)
          .where('state', '==', TripState.ENDED)
          .where('dateDeparting', '<', trip.dateDeparting)
          .orderBy('dateDeparting', 'desc')
          .limit(1);

        const querySnapshot = await simpleQuery.get();
        if (!querySnapshot.empty) {
          const previousTrip = querySnapshot?.docs?.[0]?.data?.();
          previousTrip?.metering?.overall
            ? setPrefillData(previousTrip.metering.overall?.flightTimes ?? {})
            : setPrefillData(
                last(previousTrip?.metering?.legs)?.flightTimes ?? {},
              );
        }
      } catch (error) {
        console.error('Error fetching previous trip:', error);
      }
    }
  }, [legIndex, trip]);

  if (!hasData && !prefillData) {
    fetchPrefillData();
  }

  if (hasData && prefillData) {
    setPrefillData(undefined);
  }

  return prefillData;
};
