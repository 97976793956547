/**
 * @file
 * List guest passengers and their documents
 * Allow adding/removing/editing passengers
 * Allow adding/editing/removing documents for passengers and uploading
 * images for the documents
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { List, IconButton } from 'react-native-paper';
import styled from '@emotion/native';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import { editPassenger, removePassenger } from '@appUtils/passengers';
import { usePassengers, useUserData } from '@appUtils/user';
import * as Phone from '@appUtils/phone';
import {
  Box,
  MainSurface,
  Spacer,
  SectionHeader,
  ScreenLoader,
} from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import {
  EmailFormField,
  FirstNameFormField,
  LastNameFormField,
  PhoneNumberFormField,
  NumberField,
} from '@appComponents/forms/FormFields';
import { Icon, useTheme } from '@appComponents/theme';
import { FitToContentButton } from '@appComponents/Button';
import { AddButton } from '@appComponents/List';
import { useConfirmation } from '@appComponents/Dialog';
import UserRoleIndicator from '@appComponents/UserRoleIndicator';
import { EnterToSubmit } from '@webComponents/EnterToSubmit';
import Documents from './Documents';

export const GuestPassengers = ({ ownerId }) => {
  const [owner, loading] = useUserData(ownerId);
  const { passengers, updatePassenger } = usePassengers(ownerId);
  const [addingNew, setAddingNew] = useState(false);
  const [expandedUsers, setExpandedUsers] = useState({});

  if (loading) {
    return <ScreenLoader />;
  }

  return (
    <Box>
      <SectionHeader>
        <Text color="dark" weight={500}>
          {`${owner?.firstName} ${owner?.lastName}'s Guest Passengers`.toUpperCase()}
        </Text>
        <AddButton
          disabled={addingNew}
          onPress={() => {
            setAddingNew(true);
            setExpandedUsers({
              ...expandedUsers,
              '': true,
            });
          }}>
          ADD GUEST PASSENGER
        </AddButton>
      </SectionHeader>
      <MainSurface>
        <ScrollWrapper>
          <Box ph={2}>
            <GuestPassengerHeader />
            {passengers?.map((p, index) => (
              <GuestPassengerContent
                key={`${index}${JSON.stringify(p)}`}
                passenger={p}
                ownerId={ownerId}
                setAddingNew={setAddingNew}
                updatePassenger={updatePassenger}
                passengerIndex={index}
                expandedUsers={expandedUsers}
                setExpandedUsers={setExpandedUsers}
              />
            ))}
            {addingNew && (
              <GuestPassengerContent
                key={passengers.length}
                passenger={{
                  name: '',
                  phoneNumber: '',
                  email: '',
                  weight: '',
                  documents: [],
                }}
                ownerId={ownerId}
                setAddingNew={setAddingNew}
                startExpanded={true}
                updatePassenger={updatePassenger}
                passengerIndex={passengers.length}
                expandedUsers={expandedUsers}
                setExpandedUsers={setExpandedUsers}
              />
            )}
          </Box>
        </ScrollWrapper>
      </MainSurface>
    </Box>
  );
};

const widths = {
  name: 200,
  email: 200,
  phone: 100,
  weight: 100,
  // controls gives space for the delete button
  controls: 0,
};

const GuestPassengerContent = ({
  passenger,
  ownerId,
  setAddingNew,
  updatePassenger,
  passengerIndex,
  expandedUsers,
  setExpandedUsers,
}) => {
  const expanded = expandedUsers[passenger.key] ?? false;
  const handlePress = () =>
    setExpandedUsers({ ...expandedUsers, [passenger.key]: !expanded });
  const [defaultFirstName, ...rest] = passenger?.name.split(' ');
  const defaultLastName = rest.join(' ');
  const defaultValues = {
    firstName: defaultFirstName ?? '',
    lastName: defaultLastName ?? '',
    phoneNumber: passenger?.phoneNumber ?? '',
    email: passenger?.email ?? '',
    weight: passenger?.weight ?? '',
    documents: passenger?.documents ?? [],
  };

  const {
    control,
    formState: { isDirty, isSubmitSuccessful },
    getValues,
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const update = handleSubmit(
    useCallback(
      async payload => {
        if (isDirty) {
          delete expandedUsers[passenger.key];
          setExpandedUsers({
            ...expandedUsers,
            [`${payload.firstName} ${payload.lastName}`]: expanded,
          });
          editPassenger(passenger, payload, ownerId);
          setAddingNew(false);
        }
      },
      [
        isDirty,
        expandedUsers,
        passenger,
        setExpandedUsers,
        expanded,
        ownerId,
        setAddingNew,
      ],
    ),
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [getValues, isSubmitSuccessful, reset]);
  const theme = useTheme();

  const phoneNumber = Phone.parse(passenger.phoneNumber ?? '');
  const formattedPhoneNumber = phoneNumber?.isValid()
    ? phoneNumber.formatNational()
    : passenger.phoneNumber;

  const accordionTitle = (
    <Row>
      <Box dir="row" ai="center">
        <UserRoleIndicator user={passenger} />
        <AccordionText w={widths.name}>
          {_.isEmpty(passenger.name) ? '-' : passenger.name}
        </AccordionText>
      </Box>
      <AccordionText w={widths.email}>
        {_.isEmpty(passenger.email) ? '-' : passenger.email}
      </AccordionText>
      <AccordionText w={widths.phone}>
        {_.isEmpty(formattedPhoneNumber) ? '-' : formattedPhoneNumber}
      </AccordionText>
      <AccordionText w={widths.weight}>
        {_.isEmpty(passenger.weight) ? '-' : passenger.weight}
      </AccordionText>
      <AccordionText w={widths.controls} />
    </Row>
  );

  const { confirm, confirmationNode } = useConfirmation({
    title: 'Delete Guest Passenger',
    message: `Are you sure you want to delete the guest passenger ${passenger.name}?`,
    action: () => {
      delete expandedUsers[passenger.key];
      setExpandedUsers(expandedUsers);
      removePassenger(passenger, ownerId);
    },
  });

  return (
    <GuestPassengerContentWrapper>
      <PassengerAccordion
        title={accordionTitle}
        right={() => null}
        left={() => <ExpansionIcon name={expanded ? 'menu-up' : 'menu-down'} />}
        expanded={expanded}
        onPress={handlePress}>
        <PassengerBox ph={2}>
          <Spacer size={1} />
          <Row jc="flex-start">
            <ButtonSpacer />
            <FitToContentButton disabled={!isDirty} onPress={update}>
              Save
            </FitToContentButton>
          </Row>
          <BinIconButton
            onPress={confirm}
            icon="bin"
            color={theme.colors.primary}
          />

          <Box dir="row" jc="space-between">
            <HalfWidthWrapper>
              <FirstNameFormField label="First name" control={control} />
            </HalfWidthWrapper>
            <HalfWidthWrapper>
              <LastNameFormField label="Last name" control={control} />
            </HalfWidthWrapper>
          </Box>
          <Box dir="row" jc="space-between">
            <HalfWidthWrapper>
              <EmailFormField label="Email" control={control} optional={true} />
            </HalfWidthWrapper>
            <HalfWidthWrapper>
              <PhoneNumberFormField
                label="Phone"
                placeholder="Phone number"
                control={control}
                optional={true}
              />
            </HalfWidthWrapper>
          </Box>
          <Box dir="row" jc="space-between">
            <HalfWidthWrapper>
              <NumberField
                labelCol={false}
                light={false}
                decimalScale={0}
                label="Weight (lbs)"
                name="weight"
                placeholder="Weight (lbs)"
                control={control}
                optional={true}
              />
            </HalfWidthWrapper>
          </Box>

          <Documents
            sectionTitle={`${passenger.name}'s DOCUMENTS`.toUpperCase()}
            user={passenger}
            storagePath={`${ownerId}/documents`}
            saveDocuments={documents =>
              updatePassenger({ documents }, passengerIndex)
            }
          />
          <Spacer size={4} />
          <EnterToSubmit onSubmit={update} />
        </PassengerBox>
      </PassengerAccordion>
      {confirmationNode}
    </GuestPassengerContentWrapper>
  );
};

const HalfWidthWrapper = styled.View`
  width: 48%;
`;

const IconSpacer = styled(View)`
  padding-horizontal: 22px;
`;

const GuestPassengerHeader = () => (
  <>
    <HeaderAccordion
      title={
        <Row>
          <AccordionText weight={700} w={widths.name}>
            NAME
          </AccordionText>
          <AccordionText weight={700} w={widths.email}>
            EMAIL
          </AccordionText>
          <AccordionText weight={700} w={widths.phone}>
            PHONE
          </AccordionText>
          <AccordionText weight={700} w={widths.weight}>
            WEIGHT (LBS)
          </AccordionText>
          <AccordionText weight={700} w={widths.controls} />
        </Row>
      }
      right={() => null}
      left={() => <IconSpacer />}
      expanded={false}
      onPress={() => null}
    />
    <Spacer size={0.2} />
  </>
);

const HeaderAccordion = styled(List.Accordion)(({ theme }) => ({
  marginVertical: '-15px',
  pointerEvents: 'none',
  backgroundColor: theme.colors.tableBackground,
  justifyContent: 'space-between',
}));

const AccordionText = styled(Text)(({ w }) => ({
  width: w ?? 100,
  overflow: 'hidden',
}));

const BinIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: -50,
  right: 20,
}));

const GuestPassengerContentWrapper = styled.View`
  margin-bottom: 3px;
`;

const ExpansionIcon = styled(Icon)`
  padding-horizontal: 14;
`;

const Row = styled(View)(({ jc }) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ScrollWrapper = styled.ScrollView(({ theme }) => ({
  paddingVertical: theme.layout.space(1),
}));

const PassengerAccordion = styled(List.Accordion)(({ theme }) => ({
  backgroundColor: theme.colors.section,
  paddingBottom: 10,
}));

const PassengerBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.section,
}));

const ButtonSpacer = styled(Spacer)`
  flex-basis: 60%;
`;
