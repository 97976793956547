import React from 'react';
import styled from '@emotion/native';

import { logOut } from '@appUtils/auth';
import { useMyData } from '@appUtils/api';
import Text, { Title } from '@appComponents/Text';
import AppLogo from '../../../../assets/logo/app-logo.svg';

export const WaitingRoom = () => {
  const [user, loading] = useMyData();

  if (loading) {
    return (
      <WaitingWrapper>
        <WaitingText>Loading...</WaitingText>
      </WaitingWrapper>
    );
  }

  const message = user?.archived ? (
    <>
      <AppLogo width="auto" height={100} />
      <WaitingText>Your account has been archived by the Manager.</WaitingText>
    </>
  ) : user?.companyCreator ? (
    <>
      <AppLogo width="auto" height={100} />
      <WaitingText>Welcome to FlightApp!</WaitingText>
      <WaitingText>We're setting up your account, hang tight.</WaitingText>
    </>
  ) : (
    <>
      <AppLogo width="auto" height={100} />
      <WaitingText>
        Your manager must approve your account. Please check with them for
        status.
      </WaitingText>
    </>
  );

  return (
    <WaitingWrapper>
      <LogOutLink color="primary" onPress={logOut} size="medium">
        Log Out
      </LogOutLink>
      {message}
    </WaitingWrapper>
  );
};

const WaitingWrapper = styled.View(({ theme }) => ({
  width: '100%',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.layout.space(1),
}));

const LogOutLink = styled(Text)(({ theme }) => ({
  alignSelf: 'flex-end',
  margin: theme.layout.space(2),
}));

const WaitingText = styled(Title)(({ theme }) => ({
  margin: theme.layout.space(3),
}));
