import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/native';
import { useForm } from 'react-hook-form';
import { ActivityIndicator, Badge } from 'react-native-paper';
import _ from 'lodash';

import { TextFormField } from '@appComponents/forms/FormFields';
import {
  Box,
  MainSurface,
  ScreenLayout,
  Spacer,
} from '@appComponents/ScreenLayout';
import { TrialCodeFormField } from './fields';
import Notification from '@appComponents/Notification';
import Text from '@appComponents/Text';
import Button, { FitToContentButton } from '@appComponents/Button';
import Avatar from '@appComponents/Avatar';

import { useCompanyData, useMyData } from '@appUtils/api';
import { updateAccount, updateUser } from '@appUtils/auth';
import { setForeFlightApiKey } from '@appUtils/manager';
import { ScrollView, View } from 'react-native';
import { useTheme } from '@appComponents/theme';
import { useAircraftList } from '@appUtils/aircraft';
import { capitalize } from 'lodash';
import { AircraftQuantitySelect } from '../../../components/forms/AircraftQuantitySelect';
import {
  buildCheckoutUrl,
  buildConnectAccountUrl,
  buildStripeDashboardLoginUrl,
  getSubscriptionData,
  retriveConnectAccount,
  subscriptionManagementFunction,
  updateSubscriptionFunction,
  validateAndUseTrialCodeFunction,
} from './actions';
import { PersonalDetailsForm } from './PersonalDetailsForm';
import {
  SubscriptionStatus,
  createAircraftQuantityOptions,
  formatAmount,
} from './utils';
import PasswordConfirmDialog from './PasswordConfirmDialog';

const Settings = () => {
  const [user, userLoading] = useMyData();
  const [company, companyLoading] = useCompanyData();
  const companyRef = user?.managementCompany?.ref;
  const [subscriptionData, setSubscriptionData] = useState(null);
  const theme = useTheme();
  const [accountData, setAccountData] = useState(null);
  const [inTrialCodePeriod, setInTrialCodePeriod] = useState(false);
  const [
    trialCodeExpirationDateFormatted,
    setTrialCodeExpirationDateFormatted,
  ] = useState(null);
  const { data: aircraftData } = useAircraftList();
  const [confirmPasswordModalVisible, setConfirmPasswordModalVisible] =
    useState(false);
  const [reauthenticated, setReauthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [updateSubscriptionLoading, setUpdateSubscriptionLoading] =
    useState(false);
  const [connectLoading, setConnectLoading] = useState(false);
  const [trialCodeLoading, setTrialCodeLoading] = useState(false);
  const [errorNotificationVisible, setErrorNotificationVisible] =
    useState(false);
  const [successNotificationVisible, setSuccessNotificationVisible] =
    useState(false);
  const personalDetailsDefaultValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    email: userData?.email || '',
    phoneNumber: userData?.phoneNumber || '',
    weight: userData?.weight || '',
    password: '',
    currentPassword: '',
  };
  const personalDetailsForm = useForm({
    defaultValues: personalDetailsDefaultValues,
  });

  const nonArchivedAircraftData = aircraftData?.filter(
    aircraft => !aircraft.archived,
  );

  const form = useForm({
    defaultValues: {
      aircraftQuantity: {
        name: '',
        value: '',
      },
    },
  });

  const { getValues, reset } = form;

  const trialCodeForm = useForm({
    defaultValues: {
      trialCode: '',
    },
  });

  const foreFlightForm = useForm({
    defaultValues: {
      foreFlightApiKey: company?.foreFlightApiKey ?? '',
    },
  });
  const watchForeFlightApiKey = foreFlightForm.watch('foreFlightApiKey');

  const useSaveForeFlightApiKey = useCallback(
    ({ foreFlightApiKey }) => {
      if (
        !_.isEmpty(foreFlightApiKey) &&
        foreFlightApiKey !== company?.foreFlightApiKey
      ) {
        setForeFlightApiKey({ foreFlightApiKey, companyRef });
      }
      foreFlightForm.reset({ values: { foreFlightApiKey: '' } });
    },
    [company?.foreFlightApiKey, companyRef, foreFlightForm],
  );

  const trialCode = trialCodeForm.watch('trialCode');

  useEffect(() => {
    if (user) {
      setUserData(user);
      personalDetailsForm.reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        weight: user.weight,
      });
    }
  }, [personalDetailsForm, user]);

  useEffect(() => {
    if (
      company?.aircraftQuantity &&
      getValues('aircraftQuantity.value') === ''
    ) {
      reset({
        aircraftQuantity: {
          name: company.aircraftQuantity.toString(),
          value: company.aircraftQuantity,
        },
      });
    }
    if (company?.trialCodeExpirationDate) {
      const expirationDate = new Date(
        company.trialCodeExpirationDate.seconds * 1000 +
          company.trialCodeExpirationDate.nanoseconds / 1000000,
      );
      if (!_.isEqual(trialCodeExpirationDateFormatted, expirationDate)) {
        setInTrialCodePeriod(expirationDate > new Date());
        setTrialCodeExpirationDateFormatted(expirationDate);
      }
    }
  }, [company, getValues, reset, trialCodeExpirationDateFormatted]);

  useEffect(() => {
    if (company?.id) {
      if (company?.stripeConnectAccountId) {
        retriveConnectAccount(company.id).then(data => {
          if (!_.isEqual(accountData, data)) {
            setAccountData(data);
          }
        });
      }
      if (company?.subscriptionId) {
        getSubscriptionData(company.id)
          .then(data => {
            if (!_.isEqual(subscriptionData, data.data.subscriptionData)) {
              setSubscriptionData(data.data.subscriptionData);
            }
          })
          // getSubscriptionData is causing 500 Internal Server Error, until that can be resolved this at least makes the error less disruptive
          .catch(e => console.error(e));
      }
    }
  }, [accountData, company, subscriptionData]);

  const onManageSubscription = async () => {
    setSubscriptionLoading(true);

    try {
      const data = await subscriptionManagementFunction({
        companyDocId: user.managementCompany.docId,
      });
      setSubscriptionLoading(false);
      window.location.href = data.data.portalSessionUrl;
    } catch (error) {
      console.error('Error managing subscription:', error);
      setSubscriptionLoading(false);
    }
  };

  const onSubmitUpdate = async () => {
    setUpdateSubscriptionLoading(true);
    try {
      await updateSubscriptionFunction({
        companyDocId: user.managementCompany.docId,
        quantity: aircraftQuantity.value,
      });
      setUpdateSubscriptionLoading(false);
    } catch (error) {
      console.error('Error updating subscription:', error);
      setUpdateSubscriptionLoading(false);
    }
  };

  const aircraftQuantity = form.watch('aircraftQuantity');

  const handleConnectAccount = async () => {
    setConnectLoading(true);
    try {
      const url = await buildConnectAccountUrl(company, user);
      setConnectLoading(false);
      window.location.href = url;
    } catch (error) {
      console.error('Error fetching connect account URL:', error);
    }
    setConnectLoading(false);
  };

  const handleStripeDashboardLogin = async () => {
    setConnectLoading(true);
    try {
      setConnectLoading(false);
      window.open('https://dashboard.stripe.com/account/status', '_blank');
    } catch (error) {
      console.error('Error fetching stripe dashboard login URL:', error);
    }
    setConnectLoading(false);
  };

  const handleRenewSubscription = async () => {
    try {
      setSubscriptionLoading(true);
      const url = await buildCheckoutUrl(company, user, aircraftData);
      window.location.href = url;
    } catch (error) {
      console.error('Error renewing subscription:', error);
    }
    setSubscriptionLoading(false);
  };

  const handleSubmitTrialCode = async () => {
    trialCodeForm.clearErrors();
    setTrialCodeLoading(true);
    try {
      const result = await validateAndUseTrialCodeFunction({
        trialCode: trialCode,
      });
      if (!result.data.isValid) {
        trialCodeForm.setError('trialCode', {
          message: result.data.message || 'Invalid trial code',
        });
        setTrialCodeLoading(false);
        return;
      }
    } catch (error) {
      console.error('Error submitting trial code:', error);
    }
    setTrialCodeLoading(false);
  };

  const isCanceled = company?.subscriptionStatus === 'canceled';
  const isInCanceledPeriod = subscriptionData
    ? subscriptionData?.cancellationDate &&
      new Date(subscriptionData.cancellationDate) > new Date()
    : false;
  const hasSubscription = company?.subscriptionId;

  const contentLoading = userLoading || companyLoading;

  const handleSubmitPersonalDetails = personalDetailsForm.handleSubmit(data => {
    updateUser(data);
    updateAccount(data);
    setReauthenticated(false);
    setConfirmPasswordModalVisible(false);
    personalDetailsForm.reset({
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      email: data.email || '',
      phoneNumber: data.phoneNumber || '',
      weight: data.weight || '',
      password: '',
      currentPassword: '',
    });
  });

  useEffect(() => {
    if (reauthenticated) {
      handleSubmitPersonalDetails();
    }
  }, [handleSubmitPersonalDetails, reauthenticated]);

  return (
    <ScreenLayout alignItems="stretch">
      <SettingsSurface>
        {contentLoading && <ActivityIndicator size="large" />}
        {!contentLoading && (
          <>
            <View style={notificationStyle}>
              <Notification
                color="error"
                visible={errorNotificationVisible}
                onDismiss={() => setErrorNotificationVisible(false)}>
                {
                  personalDetailsForm?.formState?.errors?.currentPassword
                    ?.message
                }
              </Notification>
              <Notification
                color="accepted"
                visible={successNotificationVisible}
                onDismiss={() => setSuccessNotificationVisible(false)}>
                Personal details updated successfully
              </Notification>
            </View>
            <PasswordConfirmDialog
              visible={confirmPasswordModalVisible}
              form={personalDetailsForm}
              setVisible={setConfirmPasswordModalVisible}
              setReauthenticated={setReauthenticated}
              user={user}
              setSuccessNotificationVisible={visible => {
                setSuccessNotificationVisible(visible);
                setErrorNotificationVisible(false);
              }}
              setErrorNotificationVisible={visible => {
                setErrorNotificationVisible(visible);
                setSuccessNotificationVisible(false);
              }}
            />
            <ScrollView>
              <Container>
                <AvatarContainer>
                  <Avatar
                    firstName={user.firstName}
                    lastName={user.lastName}
                    size={123}
                  />
                </AvatarContainer>
                {user && (
                  <PersonalDetailsForm
                    user={user}
                    form={personalDetailsForm}
                    setVisible={setConfirmPasswordModalVisible}
                  />
                )}
                <Content>
                  <HeaderBorderBottom>
                    <ContentHeader>
                      <Text color="dark" weight={500}>
                        FLIGHTAPP! SUBSCRIPTION DETAILS
                      </Text>
                      {hasSubscription ? (
                        isCanceled ? (
                          <Button
                            onPress={handleRenewSubscription}
                            disabled={subscriptionLoading}
                            loading={subscriptionLoading}>
                            <Text>Renew Subscription</Text>
                          </Button>
                        ) : (
                          <Button
                            onPress={onManageSubscription}
                            disabled={subscriptionLoading}
                            loading={subscriptionLoading}>
                            <Text>Manage Subscription</Text>
                          </Button>
                        )
                      ) : (
                        <Button
                          onPress={handleRenewSubscription}
                          disabled={subscriptionLoading}
                          loading={subscriptionLoading}>
                          <Text>Subscribe</Text>
                        </Button>
                      )}
                    </ContentHeader>
                  </HeaderBorderBottom>

                  {!hasSubscription && (
                    <Line>
                      <Text>Subscribe to FlightApp!</Text>
                    </Line>
                  )}

                  {company?.subscriptionStatus === 'canceled' &&
                    !inTrialCodePeriod && (
                      <Line>
                        <Text color="red">
                          This management company has reached the end of its
                          FlightApp! subscription. All accounts are suspended
                          until the subscription renews
                        </Text>
                      </Line>
                    )}

                  {(hasSubscription || inTrialCodePeriod) && (
                    <Line>
                      <Text>STATUS</Text>
                      <SubscriptionStatus
                        subscriptionData={subscriptionData}
                        inTrialCodePeriod={inTrialCodePeriod}
                        isInCanceledPeriod={isInCanceledPeriod}
                        company={company}
                      />
                    </Line>
                  )}

                  {aircraftData && (
                    <Line
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}>
                      <Text>MANAGED AIRCRAFT</Text>
                      <AircraftQuantitySelect
                        control={form.control}
                        label="How many aircraft do you manage?"
                        name="aircraftQuantity"
                        options={createAircraftQuantityOptions(
                          nonArchivedAircraftData.length,
                        )}
                        theme={theme}
                        disabled={isInCanceledPeriod || isCanceled}
                      />
                    </Line>
                  )}

                  {inTrialCodePeriod &&
                    company &&
                    (!hasSubscription || (hasSubscription && isCanceled)) && (
                      <Line>
                        <Text>TRIAL ENDS</Text>
                        <Text>
                          {trialCodeExpirationDateFormatted &&
                            trialCodeExpirationDateFormatted.toLocaleDateString(
                              'en-US',
                              {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                              },
                            )}
                        </Text>
                      </Line>
                    )}

                  {hasSubscription &&
                    company?.aircraftQuantity &&
                    subscriptionData &&
                    !isInCanceledPeriod &&
                    !isCanceled && (
                      <Line>
                        <Text>BILLING AMOUNT</Text>
                        <Text>{formatAmount(aircraftQuantity.value * 50)}</Text>
                      </Line>
                    )}

                  {hasSubscription &&
                    subscriptionData &&
                    !isInCanceledPeriod &&
                    !isCanceled && (
                      <Line>
                        <Text>NEXT BILLING DATE</Text>
                        <Text>
                          {subscriptionData?.nextPaymentDue
                            ? new Date(
                                subscriptionData?.nextPaymentDue,
                              ).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                              })
                            : 'N/A'}
                        </Text>
                      </Line>
                    )}

                  {hasSubscription &&
                    subscriptionData &&
                    subscriptionData.cancellationDate && (
                      <Line>
                        <Text>SUBSCRIPTION ENDS</Text>
                        <Text>
                          {new Date(
                            subscriptionData?.cancellationDate,
                          ).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          })}
                        </Text>
                      </Line>
                    )}

                  {aircraftData &&
                    company &&
                    !contentLoading &&
                    aircraftQuantity.value !== company.aircraftQuantity && (
                      <Line>
                        <Button
                          onPress={onSubmitUpdate}
                          disabled={updateSubscriptionLoading}
                          loading={updateSubscriptionLoading}
                          style={{ marginTop: 7, width: '100%' }}>
                          <Text>Confirm New Billing Amount</Text>
                        </Button>
                      </Line>
                    )}

                  <>
                    <Line>
                      <Text>ENTER TRIAL CODE</Text>
                      <TrialCodeFormField control={trialCodeForm.control} />
                    </Line>
                    <SubmitTrialCodeDiv>
                      {trialCode.length > 0 && (
                        <ButtonDiv>
                          <Button
                            onPress={handleSubmitTrialCode}
                            disabled={trialCodeLoading}
                            loading={trialCodeLoading}
                            style={{ width: '100%' }}>
                            <Text>Submit Trial Code</Text>
                          </Button>
                        </ButtonDiv>
                      )}
                    </SubmitTrialCodeDiv>
                  </>
                </Content>
                <Content>
                  {company &&
                  company?.stripeConnectAccountId &&
                  company.detailsSubmitted ? (
                    <>
                      <HeaderBorderBottom>
                        <ContentHeader>
                          <Text color="dark" weight={500}>
                            SET UP PAYMENT PLATFORM FOR TRIP EXPENSES
                          </Text>
                        </ContentHeader>
                      </HeaderBorderBottom>
                      <AccessStripeDiv>
                        <Text style={{ marginBottom: 8 }}>
                          Login to Payment Platform
                        </Text>
                        <Button
                          disabled={connectLoading}
                          loading={connectLoading}
                          onPress={handleStripeDashboardLogin}>
                          Login to Payment Platform
                        </Button>
                        {company && !company.stripeConnectLinked && (
                          <Text style={{ marginTop: 8, color: 'red' }}>
                            You need to update your business information on the
                            Payment Platform to receive payments
                          </Text>
                        )}
                      </AccessStripeDiv>
                    </>
                  ) : (
                    <>
                      <HeaderBorderBottom>
                        <ContentHeader>
                          <Text color="dark" weight={500}>
                            PAYMENT SETUP
                          </Text>
                        </ContentHeader>
                      </HeaderBorderBottom>
                      <AccessStripeDiv>
                        <Text>Configure your payment account</Text>
                        <Button
                          disabled={connectLoading}
                          loading={connectLoading}
                          onPress={handleConnectAccount}>
                          Connect to Payment Platform
                        </Button>
                      </AccessStripeDiv>
                    </>
                  )}
                  {company &&
                    company.stripeConnectAccountId &&
                    company.detailsSubmitted && (
                      <div style={{ paddingLeft: 20, width: '100%' }}>
                        <Text color="dark" weight={500}>
                          ACCOUNT INFORMATION
                        </Text>
                      </div>
                    )}
                  {accountData &&
                    accountData.account.external_accounts.data.map(
                      (account, index) => (
                        <CardContainer key={account.id}>
                          <CardContent>
                            <CardText>Account {index + 1}:</CardText>
                            <CardTextDiv>
                              <CardText>Type:</CardText>
                              <CardText>
                                {account.object === 'bank_account'
                                  ? 'Bank Account'
                                  : 'Card'}
                              </CardText>
                            </CardTextDiv>
                            <CardTextDiv>
                              <CardText>Bank Name:</CardText>
                              <CardText>{account.bank_name}</CardText>
                            </CardTextDiv>
                            <CardTextDiv>
                              <CardText>Routing Number:</CardText>
                              <CardText>{account.routing_number}</CardText>
                            </CardTextDiv>
                            <CardTextDiv>
                              <CardText>Last 4 digits:</CardText>
                              <CardText>{account.last4}</CardText>
                            </CardTextDiv>
                          </CardContent>
                        </CardContainer>
                      ),
                    )}

                  {accountData && accountData.transfers.data.length > 0 && (
                    <div
                      style={{
                        paddingLeft: 20,
                        width: '100%',
                        marginTop: 20,
                      }}>
                      <Text color="dark" weight={500}>
                        RECENT PAYMENTS RECEIVED
                      </Text>
                    </div>
                  )}
                  {accountData &&
                    accountData.transfers.data.map(transfer => (
                      <CardContainer>
                        <CardContent>
                          <CardTextDiv>
                            <CardText>Amount:</CardText>
                            <CardText>
                              {formatAmount(transfer.amount / 100)}
                            </CardText>
                          </CardTextDiv>
                          <CardTextDiv>
                            <CardText>Date:</CardText>
                            <CardText>
                              {new Date(
                                transfer.created * 1000,
                              ).toLocaleDateString()}
                            </CardText>
                          </CardTextDiv>
                          <CardTextDiv>
                            <CardText>Status:</CardText>
                            <CardText>
                              <BadgeDiv
                                style={{ width: '50%', textAlign: 'left' }}
                                color="#1EAF85">
                                <Text>Success</Text>
                              </BadgeDiv>
                            </CardText>
                          </CardTextDiv>
                          <CardTextDiv>
                            <CardText>Source Type:</CardText>
                            <CardText>
                              {capitalize(transfer.source_type)}
                            </CardText>
                          </CardTextDiv>
                        </CardContent>
                      </CardContainer>
                    ))}
                </Content>
                <Content>
                  <HeaderBorderBottom>
                    <ContentHeader>
                      <Text weight={500}>FOREFLIGHT INTEGRATION</Text>
                    </ContentHeader>
                  </HeaderBorderBottom>
                  <Spacer />
                  <Box dir="row" width="100%">
                    <Box width="50%">
                      <TextFormField
                        control={foreFlightForm.control}
                        label="ForeFlight API Key"
                        name="foreFlightApiKey"
                        placeholder={
                          company?.foreFlightApiKey
                            ? `${company.foreFlightApiKey.substring(0, 10)}...`
                            : undefined
                        }
                      />
                    </Box>
                    <Box ml={1} mt="16px">
                      <FitToContentButton
                        onPress={foreFlightForm.handleSubmit(
                          useSaveForeFlightApiKey,
                        )}
                        disabled={
                          watchForeFlightApiKey === '' ||
                          watchForeFlightApiKey === company?.foreFlightApiKey
                        }>
                        {company?.foreFlightApiKey ? 'REPLACE' : 'ADD'}
                      </FitToContentButton>
                    </Box>
                  </Box>
                </Content>
              </Container>
            </ScrollView>
          </>
        )}
      </SettingsSurface>
    </ScreenLayout>
  );
};

const notificationStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 999,
  width: '30%',
};

export const SettingsSurface = styled(MainSurface)`
  justify-content: center;
`;

export const Container = styled.View`
  align-items: center;
  margin-top: ${({ theme }) => `${theme.layout.space(1)}px`};
  padding: ${({ theme }) => `${theme.layout.space(2)}px`};
`;

export const Content = styled.View`
  width: 100%;
  max-width: 840px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => `${theme.layout.space(1)}px`};
  padding-top: ${({ theme }) => `${theme.layout.space(1)}px`};
  margin-bottom: ${({ theme }) => `${theme.layout.space(2)}px`};
`;

const Line = styled.View`
  width: 55%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ theme }) => `${theme.layout.space(1)}px`};
  padding-left: ${({ theme }) => `${theme.layout.space(1)}px`};
  padding: ${({ theme }) => `${theme.layout.space(1)}px`};
  align-items: center;
`;

export const BadgeDiv = styled(Badge)`
  padding: ${({ theme }) => `${theme.layout.space(0.8)}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
`;

export const ContentHeader = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${({ theme }) => `${theme.layout.space(1)}px`};
  padding-right: ${({ theme }) => `${theme.layout.space(1)}px`};
  margin-bottom: ${({ theme }) => `${theme.layout.space(-1)}px`};
`;

const AccessStripeDiv = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => `${theme.layout.space(2)}px`};
  padding-left: ${({ theme }) => `${theme.layout.space(1)}px`};
  display: flex;
  justify-content: space-between;
  height: 70px;
  margin-bottom: ${({ theme }) => `${theme.layout.space(4)}px`};
`;

export const HeaderBorderBottom = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.border};
  padding-bottom: ${({ theme }) => `${theme.layout.space(2)}px`};
  width: 100%;
`;

const CardContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.layout.borderRadius};
  padding: ${({ theme }) => `${theme.layout.space(1)}px`};
  border-radius: 10px;
  width: 50%;
  margin-top: ${({ theme }) => `${theme.layout.space(1)}px`};
`;

const CardContent = styled.View`
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.layout.space(0.4)}px`};
  width: 100%;
`;

const CardTextDiv = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CardText = styled.Text`
  padding: ${({ theme }) => `${theme.layout.space(0.3)}px`};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
`;

const SubmitTrialCodeDiv = styled.View`
  padding-left: ${({ theme }) => `${theme.layout.space(1)}px`};
  padding-right: ${({ theme }) => `${theme.layout.space(1)}px`};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: ${({ theme }) => `${theme.layout.space(1)}px`};
`;

const ButtonDiv = styled.View`
  margin-top: ${({ theme }) => `${theme.layout.space(1)}px`};
  width: 55%;
`;

const AvatarContainer = styled.View`
  margin-top: ${({ theme }) => `${theme.layout.space(2)}px`};
  align-items: center;
  justify-content: center;
`;

export default Settings;
