/**
 * @file
 * A component displaying user totals based on Expenses and Adjustments
 *
 * @format
 * @flow strict-local
 */
import React, { useState } from 'react';
import Text from '@appComponents/Text';
import { View, ScrollView } from 'react-native';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import styled from '@emotion/native';
import { Divider, List } from 'react-native-paper';
import { FormatDollars } from '@appUtils/accounting';
import { useTheme } from '@appComponents/theme';
import { useNavigation } from '@react-navigation/native';
import {
  ExpansionIcon,
  HeaderText,
  ContentText,
} from '@webComponents/Accounting/General';
import { Icon } from '@appComponents/theme';
import { UserRole } from '@appUtils/tripConverter';
import { useCompanyUsersFull } from '@appUtils/api';
import { AccountingTab } from '../../screens/main/Accounting';
import UserRoleIndicator from '@appComponents/UserRoleIndicator';

const matchUserReference = (userName, usersFull) => {
  return usersFull.find(
    user => `${user.firstName} ${user.lastName}` === userName,
  );
};

const hasStripeAccount = user =>
  (user?.role === UserRole.OWNER &&
    (user?.paymentMethodId || user?.stripeCustomerId)) ||
  (user?.stripeConnectAccountId && user?.detailsSubmitted);

const AccountingUsers = ({ userTotals, userDueText, tab, company = null }) => {
  const { data: usersFull } = useCompanyUsersFull({
    roles:
      tab === AccountingTab.CLIENTS
        ? [UserRole.OWNER]
        : [UserRole.PILOT, UserRole.MANAGER, UserRole.EMPLOYEE],
    company: company,
  });

  const { navigate } = useNavigation();
  return (
    <ScrollView>
      <Box pb={1} ph={2}>
        <Spacer size={2} />
        <HeaderBox>
          <Text size={'medium'} weight={600}>
            Name
          </Text>
          <TotalDueText>Total Due</TotalDueText>
        </HeaderBox>
        <Spacer size={0.75} />
        {Object.keys(userTotals).map(key => (
          <UserTripTotal
            userTotal={userTotals[key]}
            navigate={navigate}
            userDueText={userDueText}
            tab={tab}
            usersFull={usersFull}
          />
        ))}
      </Box>
    </ScrollView>
  );
};

const TripExpenseRow = ({
  tripName,
  tripTotal,
  theme,
  navigate,
  tripPath,
  tab,
}) => {
  return (
    <>
      <Row
        pl={theme.layout.space(1)}
        h={theme.layout.space(3)}
        bc={'background'}
        onClick={() => {
          navigate('Trip', {
            documentPath: tripPath,
            title: tripName,
            tab: 'EXPENSES',
            from: {
              name: 'Accounting',
              tab: tab,
            },
          });
        }}
        c={'pointer'}>
        <Row w={'70%'} jc={'flex-start'} c={'pointer'}>
          <Text size={'medium'} color="#3E8EF7" style={Underlined}>
            {tripName}
          </Text>
        </Row>
        <Row w={'30%'} jc={'flex-start'} c={'pointer'}>
          <FormatDollars
            dollars={tripTotal}
            renderText={value => (
              <Text size={'medium'} color="#3E8EF7" style={Underlined}>
                {value}
              </Text>
            )}
          />
        </Row>
      </Row>
      <Spacer size={0.25} />
    </>
  );
};

const Underlined = {
  textDecoration: 'underline',
};

const UserTripTotal = ({
  userTotal,
  navigate,
  userDueText,
  tab,
  usersFull,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  if (!userTotal) {
    return null;
  }
  const { total, userName, ...trips } = userTotal;
  const tripNames = Object.keys(trips);
  const handlePress = () => setExpanded(!expanded);
  const fullUser = matchUserReference(userName, usersFull);

  if (!fullUser) {
    return null;
  }

  return (
    <>
      <UserAccordion
        title={
          <Row>
            <Row w={'70%'} jc={'flex-start'}>
              <UserRoleIndicator user={fullUser} />
              <ContentText>{userName}</ContentText>
              {hasStripeAccount(fullUser) && (
                <StripeAccountIconContainer>
                  <Icon name="stripe-account" size={16} />
                </StripeAccountIconContainer>
              )}
            </Row>
            <Row w={'30%'} jc={'flex-end'}>
              <FormatDollars
                dollars={total}
                renderText={value => (
                  <HeaderText size={'medium'}>{value}</HeaderText>
                )}
              />
            </Row>
          </Row>
        }
        left={() => null}
        right={() => (
          <ExpansionIcon name={expanded ? 'menu-up' : 'menu-down'} />
        )}
        expanded={expanded}
        onPress={handlePress}>
        <>
          <Divider />
          <Spacer />
          <Row>
            <Column w={'1%'} />
            <Column w={'99%'} ai={'left'}>
              <Row pl={theme.layout.space(1)} w={'60%'}>
                <Row w={'70%'} jc={'flex-start'}>
                  <Text size={'medium'} weight={600}>
                    Trip Name
                  </Text>
                </Row>
                <Row w={'30%'} jc={'flex-start'}>
                  <Text size={'medium'} weight={600}>
                    {userDueText}
                  </Text>
                </Row>
              </Row>
              <Spacer size={0.25} />
              <Column w={'60%'}>
                {tripNames.map(tripName => (
                  <TripExpenseRow
                    tripName={tripName}
                    tripTotal={trips[tripName].total}
                    navigate={navigate}
                    theme={theme}
                    tripPath={trips[tripName].path}
                    tab={tab}
                  />
                ))}
                <Row
                  pl={theme.layout.space(1)}
                  h={theme.layout.space(3)}
                  bc={'primary'}>
                  <Row w={'70%'} jc={'flex-start'}>
                    <Text size={'medium'} weight={600}>
                      Total
                    </Text>
                  </Row>
                  <Row w={'30%'} jc={'flex-start'}>
                    <FormatDollars
                      dollars={total}
                      renderText={value => (
                        <Text size={'medium'} weight={600}>
                          {value}
                        </Text>
                      )}
                    />
                  </Row>
                </Row>
              </Column>
            </Column>
          </Row>
        </>
      </UserAccordion>
      <Spacer size={0.75} />
    </>
  );
};

const UserAccordion = styled(List.Accordion)(({ theme }) => ({
  justifyContent: 'center',
  paddingBottom: '4px',
  backgroundColor: theme.colors.section,
  paddingVertical: theme.layout.space(0.2),
  paddingHorizontal: theme.layout.space(1.5),
  borderRadius: theme.roundness,
  height: 50,
}));

const Row = styled(View)(({ w, bc, h, jc, pl, c, theme }) => ({
  flexDirection: 'row',
  width: w ?? '100%',
  justifyContent: jc ?? 'space-evenly',
  alignItems: 'center',
  backgroundColor: theme.colors[bc] ?? undefined,
  height: h ?? undefined,
  paddingLeft: pl ?? 0,
  borderRadius: theme.roundness,
  cursor: c ?? 'auto',
}));

const Column = styled(View)(({ w, ai, ml }) => ({
  flexDirection: 'column',
  width: w ?? 'auto',
  justifyContent: 'space-around',
  alignItems: ai ?? 'center',
}));

const HeaderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingHorizontal: 30,
});

const StripeAccountIconContainer = styled(View)(({ theme }) => ({
  borderRadius: 10,
  backgroundColor: theme.colors.fieldBackground,
  padding: 6,
  marginLeft: 12,
}));

const TotalDueText = styled(Text)(({ theme }) => ({
  alignItems: 'flex-end',
  paddingRight: theme.layout.space(4),
  fontWeight: 600,
  fontSize: 'medium',
}));

export default AccountingUsers;
