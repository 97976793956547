/**
 * @file
 * A component displaying Accounting Set Up
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { View, ViewStyle } from 'react-native';
import { Badge, Divider, List } from 'react-native-paper';
import _ from 'lodash';
import styled from '@emotion/native';

import { updateAircraft, useAircraftList } from '@appUtils/aircraft';
import { useCompanyData, useMyCompanyRef } from '@appUtils/api';
import { updateCompanyConfig } from '@appUtils/manager';
import { UserRole } from '@appUtils/tripConverter';
import Button from '@appComponents/Button';
import Radio from '@appComponents/Radio';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { Icon } from '@appComponents/theme';
import { TextFormField } from '@appComponents/forms/FormFields';
import { useTheme } from '@appComponents/theme';
import DropDownRadioButton from '@webComponents/DropDownRadioButton';
import { ExpansionIcon } from '@webComponents/Accounting/General';
import { EnterToSubmit } from '@webComponents/EnterToSubmit';

type AccountingSetUpProps = {
  style?: ViewStyle,
};

const AccountingSetUp = ({ style }: AccountingSetUpProps) => {
  const [companyRef] = useMyCompanyRef();
  const [company] = useCompanyData();
  const { data: aircraft } = useAircraftList();
  const companyConfig = useMemo(() => company?.config ?? {}, [company?.config]);
  const categories = companyConfig.expenseTypes ?? [];
  const { control, handleSubmit, reset } = useForm({
    defaultValues: companyConfig,
  });
  const [addingNew, setAddingNew] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);

  const { isSubmitSuccessful } = useFormState({ control });

  useEffect(() => {
    reset(companyConfig);
  }, [companyConfig, isSubmitSuccessful, reset]);

  return (
    <AccountingSetUpBox>
      <Box pv={1} style={style} ph={2}>
        <HeaderBox>
          <HeaderText>GLOBAL EXPENSE CATEGORIES</HeaderText>
          <CenteredButton
            onPress={() => {
              setAddingNew(true);
              setIsEditingGroup(true);
            }}
            disabled={addingNew || isEditingGroup}>
            <ButtonContentWrapper>
              <Icon
                name="plus"
                size={16}
                stroke="black"
                style={{ marginRight: 5 }}
              />
              <Text color={'black'}>Add Expense Category</Text>
            </ButtonContentWrapper>
          </CenteredButton>
        </HeaderBox>
        <SectionDivider />
        <CategorySectionWrapper>
          {categories.map((category, i) => (
            <CategoryItem
              category={category}
              index={i}
              categoryLength={categories.length}
              control={control}
              companyConfig={companyConfig}
              companyRef={companyRef}
              handleSubmit={handleSubmit}
              isEditingGroup={isEditingGroup}
              setIsEditingGroup={setIsEditingGroup}
            />
          ))}
          {addingNew && (
            <CategoryItem
              category={{ name: '', fuelExpense: false }}
              index={-1}
              categoryLength={categories.length}
              control={control}
              companyConfig={companyConfig}
              companyRef={companyRef}
              handleSubmit={handleSubmit}
              setAddingNew={setAddingNew}
              isEditingGroup={isEditingGroup}
              setIsEditingGroup={setIsEditingGroup}
            />
          )}
        </CategorySectionWrapper>

        <Spacer />
        <HeaderText>INDIVIDUAL AIRCRAFT PAYMENT METHODS</HeaderText>
        <SectionDivider />
        {aircraft.map(a => (
          <AircraftPaymentMethods aircraft={a} />
        ))}
      </Box>
    </AccountingSetUpBox>
  );
};

const FuelExpenseRadios = ({ isFuel, setIsFuel }) => {
  const theme = useTheme();
  return (
    <Column>
      <FuelExpenseText>Fuel Expense</FuelExpenseText>
      <WidthlessRow>
        <FuelRadio
          value={true}
          label={'Yes'}
          checked={isFuel}
          onChange={() => setIsFuel(true)}
          style={radioStyle({ theme })}
        />
        <FuelRadio
          value={false}
          label={'No'}
          checked={!isFuel}
          onChange={() => setIsFuel(false)}
          style={radioStyle({ theme })}
        />
      </WidthlessRow>
    </Column>
  );
};

const CategoryItem = ({
  category,
  index,
  categoryLength,
  control,
  companyConfig,
  companyRef,
  handleSubmit,
  setAddingNew,
  isEditingGroup,
  setIsEditingGroup,
}) => {
  const [editingName, setEditingName] = useState(index > -1 ? false : true);
  const [isFuel, setIsFuel] = useState(category.fuelExpense);
  const newIndex = index > -1 ? index : categoryLength;
  const expenseCategoryCallback = useExpenseCategoryCallback({
    index,
    newIndex,
    setEditingName,
    setAddingNew,
    isFuel,
    companyRef,
    companyConfig,
    setIsEditingGroup,
  });

  const deleteExpenseCategory = useDeleteExpenseCategory({
    companyConfig,
    index,
    companyRef,
  });

  const fieldStyle = { width: '85%' };

  return (
    <CategoryItemWrapper editingName={editingName}>
      {editingName ? (
        <>
          <Section w={'47%'}>
            <ExpenseCategoryField
              name={`expenseTypes[${newIndex}].name`}
              control={control}
              style={fieldStyle}
            />
          </Section>
          <Section w={'30%'}>
            <FuelExpenseRadios isFuel={isFuel} setIsFuel={setIsFuel} />
          </Section>
          <Section w={'23%'}>
            <Button
              icon={'check'}
              color={'transparent'}
              mr={0.5}
              onPress={handleSubmit(expenseCategoryCallback)}
            />
            <Button
              color={'transparent'}
              icon={'close'}
              onPress={() => {
                setEditingName(false);
                setIsEditingGroup(false);
                if (index < 0) {
                  setAddingNew(false);
                }
              }}
            />
            <EnterToSubmit onSubmit={handleSubmit(expenseCategoryCallback)} />
          </Section>
        </>
      ) : (
        <>
          <Section w={'77%'}>
            <CategoryText>{category.name}</CategoryText>
            {category.fuelExpense && <FuelIcon name="fuel" />}
          </Section>
          {isEditingGroup ? (
            <Section w={'23%'} />
          ) : (
            <Section w={'23%'} jc={'center'}>
              <Button
                iconSize={18}
                color={'transparent'}
                icon={'edit'}
                mr={0.5}
                onPress={() => {
                  setEditingName(true);
                  setIsEditingGroup(true);
                }}
              />
              <Button
                iconSize={18}
                color={'transparent'}
                icon={'bin'}
                onPress={handleSubmit(deleteExpenseCategory)}
              />
            </Section>
          )}
        </>
      )}
    </CategoryItemWrapper>
  );
};

const PaymentMethod = ({
  method,
  index,
  options,
  control,
  aircraft,
  handleSubmit,
  setAddingNew,
  addingNew,
  isEditingGroup,
  setIsEditingGroup,
}) => {
  const [billedTo, setBilledTo] = useState({
    role: method?.billedToRole ?? UserRole.MANAGER,
    label: method?.billedToName ?? '',
    value: method?.billedToName ?? 'Manager',
  });
  const [editingName, setEditingName] = useState(index > -1 ? false : true);
  const paymentMethodCallback = usePaymentMethodCallback({
    aircraft,
    index,
    setEditingName,
    setAddingNew,
    billedTo,
    setIsEditingGroup,
  });
  const deletePaymentMethod = useDeletePaymentMethod({
    aircraft,
    index,
    setAddingNew,
  });

  useEffect(() => {
    if (index > -1) {
      const paymentMethods = aircraft?.paymentMethods ?? [];
      const paymentMethod = paymentMethods[index];
      if (
        paymentMethod.billedToRole !== billedTo.role ||
        paymentMethod.billedToName !== billedTo.value
      ) {
        paymentMethod.billedToRole = billedTo.role;
        paymentMethod.billedToName = billedTo.value;
        paymentMethods[index] = paymentMethod;
        updateAircraft({ paymentMethods }, aircraft.path);
      }
    }
  }, [aircraft.path, aircraft?.paymentMethods, billedTo, index]);

  const newIndex = index > -1 ? index : aircraft.paymentMethods?.length ?? 0;
  return (
    <PaymentMethodItemWrapper addingNew={addingNew} editingName={editingName}>
      <LeftSection>
        {editingName ? (
          <>
            <Section w={'70%'}>
              <PaymentMethodField
                name={`paymentMethods[${newIndex}].name`}
                control={control}
              />
            </Section>
            <Button
              color={'transparent'}
              iconSize={18}
              icon={'check'}
              mr={1}
              onPress={handleSubmit(paymentMethodCallback)}
            />
            <Button
              color={'transparent'}
              icon={'close'}
              iconSize={18}
              onPress={() => {
                setEditingName(false);
                setIsEditingGroup(false);
                if (index < 0) {
                  setAddingNew(false);
                }
              }}
            />
            <EnterToSubmit onSubmit={handleSubmit(paymentMethodCallback)} />
          </>
        ) : (
          <>
            <Section w={'70%'}>
              <PaymentMethodText>{method?.name}</PaymentMethodText>
            </Section>
            {!isEditingGroup && (
              <>
                <Button
                  color={'transparent'}
                  iconSize={18}
                  icon={'edit'}
                  mr={1}
                  onPress={() => {
                    setEditingName(true);
                    setIsEditingGroup(true);
                  }}
                />
                <Button
                  color={'transparent'}
                  icon={'bin'}
                  iconSize={18}
                  onPress={handleSubmit(deletePaymentMethod)}
                />
              </>
            )}
          </>
        )}
      </LeftSection>
      <RightSection style={{ justifyContent: 'flex-end' }}>
        <Text style={{ fontSize: 14, fontWeight: 500, marginRight: 10 }}>
          IS BILLED TO
        </Text>
        <DropDownRadioButton
          value={billedTo}
          valuePicker={pickDropDownValue}
          onChange={setBilledTo}
          options={options}
          checked={billedTo.role !== UserRole.MANAGER}
          control={control}
        />
      </RightSection>
    </PaymentMethodItemWrapper>
  );
};

const pickDropDownValue = billedTo => billedTo;

const AircraftPaymentMethods = ({ aircraft }) => {
  const [expanded, setExpanded] = useState(false);
  const handlePress = () => setExpanded(!expanded);
  const [addingNew, setAddingNew] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: aircraft,
  });

  const { isSubmitSuccessful } = useFormState({ control });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(aircraft);
    }
  }, [aircraft, isSubmitSuccessful, reset]);

  const aircraftTitle = aircraft?.name
    ? `${aircraft.name} | ${aircraft.tailNumber}`
    : aircraft?.tailNumber;

  const billedToOptions = getBilledToOptions(aircraft);

  const AircraftName = () => (
    <Row style={{ width: '100%', justifyContent: 'flex-start', padding: 8 }}>
      <ExpansionIcon
        name={expanded ? 'menu-up' : 'menu-down'}
        style={{ marginRight: 10 }}
      />
      <Text style={{ fontSize: 16, marginRight: 10, fontWeight: 500 }}>
        {aircraft.name}
      </Text>
      <AircraftTailNumberBadge>
        <Text>{aircraft.tailNumber}</Text>
      </AircraftTailNumberBadge>
    </Row>
  );

  return (
    <>
      <AircraftAccordion
        right={() => null}
        left={() => <AircraftName />}
        expanded={expanded}
        onPress={handlePress}>
        <AircraftBox>
          <Spacer size={0.5} />
          <PaymentSectionWrapper>
            <PaymentSectionHeader />
            <Spacer size={1.5} />
            <>
              {aircraft?.paymentMethods?.map((method, i) => (
                <PaymentMethod
                  method={method}
                  index={i}
                  options={billedToOptions}
                  control={control}
                  aircraft={aircraft}
                  handleSubmit={handleSubmit}
                  setAddingNew={setAddingNew}
                  key={i}
                  isEditingGroup={isEditingGroup}
                  setIsEditingGroup={setIsEditingGroup}
                />
              ))}
            </>
            {addingNew && (
              <PaymentMethod
                method={{ name: '' }}
                index={-1}
                options={billedToOptions}
                control={control}
                aircraft={aircraft}
                handleSubmit={handleSubmit}
                setAddingNew={setAddingNew}
                addingNew={addingNew}
                isEditingGroup={isEditingGroup}
                setIsEditingGroup={setIsEditingGroup}
              />
            )}
            <AddPaymentMethodButton>
              <Button
                onPress={() => {
                  setAddingNew(true);
                  setIsEditingGroup(true);
                }}
                disabled={addingNew || isEditingGroup}>
                <ButtonContentWrapper>
                  <Icon
                    name="plus"
                    size={16}
                    stroke="black"
                    style={{ marginRight: 5 }}
                  />
                  <Text color={'black'}>Add Payment Method</Text>
                </ButtonContentWrapper>
              </Button>
            </AddPaymentMethodButton>
          </PaymentSectionWrapper>

          <Spacer />
        </AircraftBox>
      </AircraftAccordion>
      <Spacer size={0.4} />
    </>
  );
};

const PaymentSectionHeader = () => (
  <Row style={{ width: '100%', justifyContent: 'flex-start' }}>
    <HeaderText fontWeight={600}>PAYMENT METHOD</HeaderText>
  </Row>
);

const getBilledToOptions = aircraft => {
  const managerOptions = [
    { role: UserRole.MANAGER, value: 'Manager', label: 'Manager' },
  ];
  const ownerOptions = [
    { role: UserRole.OWNER, value: 'Owner', label: 'Client' },
  ];
  const pilotOptions = [
    { role: UserRole.PILOT, value: 'Pilot', label: 'Pilot' },
  ];
  if (aircraft?.owners) {
    aircraft.owners.map(owner => {
      const ownerName = `${owner.firstName} ${owner.lastName}`;
      ownerOptions.push({
        role: UserRole.OWNER,
        value: ownerName,
        label: ownerName,
      });
    });
  }
  if (aircraft?.pilots) {
    aircraft.pilots.map(pilot => {
      const pilotName = `${pilot.firstName} ${pilot.lastName}`;
      pilotOptions.push({
        role: UserRole.PILOT,
        value: pilotName,
        label: pilotName,
      });
    });
  }

  return [
    { label: 'Manager', options: managerOptions },
    { label: 'Clients', options: ownerOptions },
    { label: 'Pilots', options: pilotOptions },
  ];
};

const useDeletePaymentMethod = ({ aircraft, index, setAddingNew }) =>
  useCallback(() => {
    const paymentMethods = aircraft?.paymentMethods;
    if (paymentMethods[index]) {
      paymentMethods.splice(index, 1);
    } else {
      setAddingNew(false);
    }
    updateAircraft({ paymentMethods }, aircraft.path);
  }, [aircraft.path, aircraft?.paymentMethods, index, setAddingNew]);

const usePaymentMethodCallback = ({
  aircraft,
  index,
  setEditingName,
  setAddingNew,
  billedTo,
  setIsEditingGroup,
}) =>
  useCallback(
    payload => {
      const paymentMethod =
        index > -1
          ? payload.paymentMethods[index]
          : payload.paymentMethods.pop();
      if (!_.isEmpty(paymentMethod?.name)) {
        const paymentMethods = aircraft?.paymentMethods ?? [];
        if (index > -1) {
          paymentMethods[index] = paymentMethod;
        } else {
          paymentMethod.billedToRole = billedTo.role;
          paymentMethod.billedToName = billedTo.value;
          paymentMethods.push(paymentMethod);
        }
        updateAircraft({ paymentMethods }, aircraft.path);
        setEditingName(false);
        setIsEditingGroup(false);
        if (index < 0) {
          setAddingNew(false);
        }
      }
    },
    [
      aircraft.path,
      aircraft?.paymentMethods,
      billedTo,
      index,
      setAddingNew,
      setEditingName,
      setIsEditingGroup,
    ],
  );

const useDeleteExpenseCategory = ({ companyConfig, index, companyRef }) =>
  useCallback(() => {
    const config = companyConfig;
    config?.expenseTypes.splice(index, 1);
    updateCompanyConfig({ config, companyRef });
  }, [companyConfig, companyRef, index]);

const useExpenseCategoryCallback = ({
  index,
  newIndex,
  setEditingName,
  setAddingNew,
  isFuel,
  companyRef,
  companyConfig,
  setIsEditingGroup,
}) =>
  useCallback(
    payload => {
      if (_.isEmpty(payload?.expenseTypes[newIndex]?.name)) {
        return;
      }
      const config = companyConfig;
      config.expenseTypes[newIndex] = payload.expenseTypes[newIndex];
      config.expenseTypes[newIndex].fuelExpense = isFuel;
      updateCompanyConfig({ config, companyRef });
      setEditingName(false);
      setIsEditingGroup(false);
      if (index < 0) {
        setAddingNew(false);
      }
    },
    [
      companyConfig,
      companyRef,
      index,
      isFuel,
      newIndex,
      setAddingNew,
      setEditingName,
      setIsEditingGroup,
    ],
  );

const CategorySectionWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginHorizontal: theme.layout.space(1),
}));

const Row = styled(View)(({ w }) => ({
  flexDirection: 'row',
  width: w ?? '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

const CategoryItemWrapper = styled(Row)(({ theme, editingName }) => ({
  flexDirection: 'row',
  width: '49%',
  alignItems: 'center',
  marginBottom: theme.layout.space(0.5),
  marginRight: theme.layout.space(0.5),
  backgroundColor: theme.colors.section,
  borderRadius: theme.roundness,
  paddingHorizontal: theme.layout.space(0.75),
  paddingVertical: theme.layout.space(0.75),
  ...(editingName && {
    width: '99%',
    paddingVertical: theme.layout.space(0),
  }),
}));

const SectionDivider = styled(Divider)(({ theme }) => ({
  marginVertical: theme.layout.space(1),
}));

const HeaderText = styled.Text(({ theme, pl, fontWeight }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.medium,
  paddingLeft: pl ?? 0,
  fontWeight: fontWeight ?? 400,
}));

const FuelExpenseText = styled.Text(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.medium,
  marginBottom: theme.layout.space(-0.8),
}));

const CategoryText = styled.Text(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.medium,
  alignSelf: 'flex-start',
}));

const PaymentMethodText = styled.Text(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.medium,
  alignSelf: 'flex-start',
}));

const Column = styled(View)(() => ({
  flexDirection: 'column',
  width: 'auto',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

const WidthlessRow = styled(View)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

const Section = styled(View)(({ w, jc }) => ({
  flexDirection: 'row',
  width: w ?? '100%',
  alignItems: 'center',
  justifyContent: jc ?? 'flex-start',
}));

const PaymentMethodItemWrapper = styled(Row)(
  ({ theme, addingNew, editingName }) => ({
    width: addingNew || editingName ? '97%' : '48%',
    marginRight: theme.layout.space(1),
    backgroundColor: theme.colors.section,
    borderRadius: theme.roundness,
    paddingHorizontal: theme.layout.space(1),
    marginBottom: theme.layout.space(0.5),
    paddingVertical: theme.layout.space(0.5),
  }),
);

const LeftSection = styled(View)(() => ({
  flexDirection: 'row',
  width: '40%',
  alignItems: 'center',
}));

const RightSection = styled(View)(() => ({
  flexDirection: 'row',
  width: '60%',
  alignItems: 'center',
}));

const FuelIcon = styled(Icon)(({ theme }) => ({
  marginLeft: theme.layout.space(1),
}));

const AircraftAccordion = styled(List.Accordion)(({ theme }) => ({
  paddingBottom: theme.layout.space(0.75),
  borderRadius: theme.roundness,
}));

const PaymentSectionWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

const AircraftBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.background,
}));

const FuelRadio = styled(Radio)(({ theme }) => ({
  width: theme.layout.space(9),
}));

const radioStyle = ({ theme }) => {
  return {
    width: theme.layout.space(5),
    marginLeft: theme.layout.space(-1),
  };
};

const CenteredButton = styled(Button)(({}) => ({
  height: '36px',
  justifyContent: 'center',
  width: 'fit-content',
}));

const ExpenseCategoryField = styled(TextFormField)(({}) => ({
  height: '45px',
  marginBottom: '-15px',
}));

const PaymentMethodField = styled(TextFormField)(({}) => ({
  height: '40px',
  marginBottom: '-15px',
}));

const AccountingSetUpBox = styled(View)(({ theme }) => ({
  borderWidth: 1,
  borderColor: theme.colors.border,
  margin: theme.layout.space(1.5),
  padding: theme.layout.space(0.5),
  paddingVertical: theme.layout.space(1),
  borderRadius: theme.roundness,
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ButtonContentWrapper = styled(View)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 2,
}));

const AircraftTailNumberBadge = styled(Badge)(() => ({
  marginLeft: 10,
  paddingHorizontal: 8,
  borderRadius: 4,
  backgroundColor: '#1EAF85',
  fontWeight: 500,
}));

const AddPaymentMethodButton = styled(Button)(({ theme }) => ({
  width: '98%',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  paddingBottom: theme.layout.space(0.5),
  backgroundColor: theme.colors.background,
}));

export default AccountingSetUp;
