/**
 * @file
 * Components for exporting accounting information related to a trip
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import styled from '@emotion/native';
import { jsPDF } from 'jspdf';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { CSVLink } from 'react-csv';
import _ from 'lodash';

import { numDecimalPlaces } from '@appUtils/numbers';
import { UserRole } from '@appUtils/tripConverter';
import {
  sumAdjustments,
  getExpenseTotals,
  groupExpenseAdjustments,
} from '@appUtils/accounting';
import { minutesToFlightTime } from '@appUtils/trip';
import { arrayToCSV, fileTimestamp, getFileName } from '@appUtils/exports';
import Text from '@appComponents/Text';
import Button from '@appComponents/Button';
import { Icon } from '@appComponents/theme';
import { Spacer, Box } from '@appComponents/ScreenLayout';
import { getStorageDownloadUrls } from '@webUtils/storage';

const ExportWrapper = styled.View(({ ml }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: ml,
}));

const getCSVName = tripName => {
  return getFileName(tripName, 'csv');
};

const getPDFName = tripName => {
  return getFileName(tripName, 'pdf');
};

const getAdjustmentCSVName = (tripName, userName, userRole) => {
  let desc = 'Expense History Owed ';
  desc += userRole === UserRole.OWNER ? `by ${userName}` : `to ${userName}`;
  return getFileName(tripName, 'csv', desc);
};

const getCSVData = (data, fuelUnits) => {
  const csvData = [
    [
      'Expense Date',
      'Category',
      'Payment Method',
      'Description',
      'Amount',
      'Location',
      `Fuel Amount${fuelUnits ? ` (${fuelUnits})` : ''}`,
    ],
  ];
  if (data) {
    data.map(expense => {
      const date = expense.date ? expense.date.toLocaleDateString() : '';
      const type = expense.type.name ?? '';
      const paymentMethod = expense.paymentMethod?.name ?? '';
      const description = expense.description ?? '';
      const amount = expense.amount ?? 0;
      const location = expense.location ?? '';
      const fuelAmount = expense.type.fuelExpense
        ? expense.fuelAmount ?? 0
        : 'N/A';
      csvData.push([
        date,
        type,
        paymentMethod,
        description,
        amount,
        location,
        fuelAmount,
      ]);
    });
  }

  return csvData;
};

const getCSVAdjustmentData = (trip, userName, userRole) => {
  const { totalDueFromOwner, totalDueToPic } = getExpenseTotals(
    trip?.expenses,
    userName,
    userName,
  );

  const { ownerAdjustments, picAdjustments } = groupExpenseAdjustments(
    trip.expenseAdjustments,
    userName,
    userName,
    userName,
  );

  let currentDue, totalDue;
  if (userRole === UserRole.OWNER) {
    totalDue = totalDueFromOwner;
    currentDue = totalDue + sumAdjustments(ownerAdjustments);
  } else {
    totalDue = totalDueToPic;
    currentDue = totalDue + sumAdjustments(picAdjustments);
  }

  const csvData = [
    ['Total Due', totalDue.toFixed(2)],
    [
      'Adjustment Date',
      'Balance Before Adjustment',
      'Adjustment',
      'Balance After Adjustment',
      'Note',
    ],
  ];

  if (trip && trip.expenseAdjustments) {
    trip.expenseAdjustments.map(adj => {
      const name = adj.dueToName;

      if (!name || name !== userName) {
        return;
      }

      const date = adj.dateCreated
        ? DateTime.fromSeconds(adj.dateCreated.seconds)
            .toJSDate()
            .toLocaleDateString()
        : '';
      const beforeAmount = adj.amountDue ?? 0;
      const adjAmount = adj.adjustment ?? 0;
      const afterAmount = beforeAmount + adjAmount;
      const note = adj.note ?? '';
      csvData.push([
        date,
        beforeAmount.toFixed(2),
        adjAmount.toFixed(2),
        afterAmount.toFixed(2),
        note,
      ]);
    });
  }

  if (csvData.length > 2) {
    csvData[csvData.length - 1][3] = currentDue.toFixed(2);
  }

  return csvData;
};

const createAdjustmentFiles = async (trip, tripName) => {
  const ownerName = trip?.owner
    ? `${trip?.owner?.firstName} ${trip?.owner?.lastName}`
    : '';
  const picName = trip?.pilots[0]
    ? `${trip?.pilots[0]?.firstName} ${trip?.pilots[0]?.lastName}`
    : '';
  const sicName = trip?.pilots[1]
    ? `${trip?.pilots[1]?.firstName} ${trip?.pilots[1]?.lastName}`
    : '';

  const zip = new JSZip();

  if (ownerName) {
    const ownerData = getCSVAdjustmentData(trip, ownerName, UserRole.OWNER);
    const ownerFileName = getAdjustmentCSVName(
      tripName,
      ownerName,
      UserRole.OWNER,
    );
    zip.file(ownerFileName, arrayToCSV(ownerData));
  }
  if (picName) {
    const picData = getCSVAdjustmentData(trip, picName, UserRole.PILOT);
    const picFileName = getAdjustmentCSVName(tripName, picName, UserRole.PILOT);
    zip.file(picFileName, arrayToCSV(picData));
  }
  if (sicName) {
    const sicData = getCSVAdjustmentData(trip, sicName, UserRole.PILOT);
    const sicFileName = getAdjustmentCSVName(tripName, sicName, UserRole.PILOT);
    zip.file(sicFileName, arrayToCSV(sicData));
  }

  zip.generateAsync({ type: 'blob' }).then(zipFile => {
    saveAs(zipFile, `${tripName} Expense Adjustments ${fileTimestamp()}.zip`);
  });
};

const createItineraryFile = async (
  trip,
  tripName,
  companyName,
  withDocs = false,
) => {
  // https://raw.githack.com/MrRio/jsPDF/master/docs/index.html
  const doc = new jsPDF(pdfOptions);
  const headerTextSize = 20;
  const bodyTextSize = 16;
  const footerTextSize = 12;

  const topMargin = 0.75;
  const leftMargin = 0.5;
  const bottomMargin = 0.25;

  const bodyLinesPerPage = 40;
  const bodyLinesPage1 = 38;

  doc.setFontSize(headerTextSize);
  doc.text(`${tripName} Itinerary`, leftMargin, topMargin);

  let bodyText = companyName;
  bodyText += '\nPilots:';
  for (let pilot of trip.pilots) {
    bodyText += `\n\t${pilot.firstName} ${pilot.lastName}`;
  }

  for (let i = 0; i < trip.legs.length; i++) {
    const leg = trip.legs[i];
    const date = leg.departureDate.toLocaleString(
      DateTime.DATE_MED_WITH_WEEKDAY,
    );
    const time = leg.departureDate.toFormat('hh:mm a ZZZZ');

    bodyText += `\n\nLeg ${i + 1}: ${leg.from} to ${leg.to}`;
    bodyText += `\nDeparture: ${[date, time].join(' @ ')}`;
    if (_.isFinite(leg?.flightTime) && leg.flightTime > 0) {
      bodyText += `\nExpected Flight Time: ${minutesToFlightTime(
        leg.flightTime,
        'explicit',
      )}`;
    }
    bodyText += '\nPassengers:';
    for (let p of leg.passengers) {
      if (p?.name) {
        bodyText += `\n\t${p.name}`;
      } else {
        bodyText += `\n\t${p.firstName} ${p.lastName}`;
      }
    }
  }
  const bodyTextLines = bodyText.split('\n');

  // Count total pages
  let totalPages = 1;
  let pageLineCounter = bodyLinesPage1;
  while (pageLineCounter < bodyTextLines.length) {
    totalPages++;
    pageLineCounter += bodyLinesPerPage;
  }

  // Find the breaks between legs
  const breakIndices = [];
  for (let i = 0; i < bodyTextLines.length; i++) {
    if (bodyTextLines[i] === '') {
      breakIndices.push(i);
    }
  }
  breakIndices.push(bodyTextLines.length);

  let firstPage = true;
  let lastPage = false;
  let currentLine = 0;
  let currentPageNumber = 1;

  while (!lastPage) {
    doc.setFontSize(bodyTextSize);
    if (!firstPage) {
      // Remove the extra line from the double space on a page break
      currentLine++;
    }
    let breakIndex = 0;
    let currentPage = [];
    const currentPageLines = firstPage ? bodyLinesPage1 : bodyLinesPerPage;
    // Decide where to break the page
    for (let i = breakIndices.length - 1; i >= 0; i--) {
      if (breakIndices[i] <= currentLine + currentPageLines) {
        if (breakIndices[i] === breakIndices[breakIndices.length - 1]) {
          lastPage = true;
        }
        breakIndex = breakIndices[i];
        break;
      }
    }
    while (currentLine < breakIndex) {
      currentPage.push(bodyTextLines[currentLine]);
      currentLine++;
    }

    doc.text(
      currentPage.join('\n'),
      leftMargin,
      topMargin + (firstPage ? 0.5 : 0),
      {
        maxWidth: 8.5 - leftMargin * 2,
      },
    );

    doc.setFontSize(footerTextSize);
    doc.text(
      `${DateTime.now().toFormat('dd LLL y hh:mm:ss a ZZZZ')}`,
      leftMargin,
      11 - bottomMargin,
      {
        maxWidth: 8.5 - leftMargin * 2,
      },
    );
    const pageX = 8.5 - leftMargin - 0.75;
    doc.text(
      `Page ${currentPageNumber} of ${totalPages}`,
      pageX,
      11 - bottomMargin,
      {
        maxWidth: 8.5 - pageX,
      },
    );

    if (!lastPage) {
      doc.addPage(pdfOptions);
    }

    firstPage = false;
    currentPageNumber++;
  }

  if (!withDocs) {
    return doc.save(getFileName(tripName, 'pdf', 'Itinerary'));
  }

  const pdfBlob = doc.output('blob');
  const zip = new JSZip();
  zip.file(getFileName(tripName, 'pdf', 'Itinerary'), pdfBlob);

  const storageUrls = trip?.documents?.map(d => d.url);
  const storageFiles = await getStorageDownloadUrls(storageUrls);

  for (let i = 0; i < trip.documents.length; i++) {
    const tripDoc = trip.documents[i];
    const response = await fetch(storageFiles[i].uri);
    const responseBlob = await response.blob();

    const urlArray = tripDoc.url.split('/');
    zip.file(urlArray.pop(), responseBlob);
  }

  zip.generateAsync({ type: 'blob' }).then(zipFile => {
    saveAs(
      zipFile,
      `${tripName} Itinerary and Documents ${fileTimestamp()}.zip`,
    );
  });
};

const pdfOptions = {
  orientation: 'portrait',
  unit: 'in',
  format: [8.5, 11],
};

// https://stackoverflow.com/questions/22172604/convert-image-from-url-to-base64
const imageUrlToBase64 = async url => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

const getImageDimensions = base64Image => {
  return new Promise((resolved, rejected) => {
    var i = new Image();
    i.onload = () => {
      resolved({ w: i.width, h: i.height });
    };
    i.src = base64Image;
  });
};

const exportPDF = async (data, images, tripName, fuelUnits) => {
  // https://raw.githack.com/MrRio/jsPDF/master/docs/index.html
  const doc = new jsPDF(pdfOptions);
  const bodyTextSize = 16;
  const footerTextSize = 12;
  doc.setFontSize(bodyTextSize);

  const topMargin = 0.25;
  const bottomMargin = 0.5;
  const leftMargin = 0.25;

  const expenseCount = data.length;
  let imageCount = 0;
  let imageExpenseLabels = [];
  for (let i = 0; i < data.length; i++) {
    const numPhotos = data[i].photoUrls.length;
    imageCount += numPhotos;
    for (let j = 0; j < numPhotos; j++) {
      imageExpenseLabels.push(i + 1);
    }
  }

  const expensesPerPage = 3;
  const imagesPerPage = 1;
  const totalPages =
    Math.ceil(expenseCount / expensesPerPage) +
    Math.ceil(imageCount / imagesPerPage);

  footerPDF({
    pdfDoc: doc,
    leftMargin,
    bottomMargin,
    tripName,
    bodyTextSize,
    footerTextSize,
    currentPage: 1,
    totalPages,
  });

  const pageNumberAfterText = addExpenseTextToPDF({
    pdfDoc: doc,
    data,
    expensesPerPage,
    leftMargin,
    topMargin,
    bottomMargin,
    tripName,
    bodyTextSize,
    footerTextSize,
    startingPage: 1,
    totalPages,
    fuelUnits,
  });

  await addImagesToPdf({
    pdfDoc: doc,
    images,
    imagesPerPage,
    imageExpenseLabels,
    leftMargin,
    topMargin,
    bottomMargin,
    tripName,
    bodyTextSize,
    footerTextSize,
    startingPage: pageNumberAfterText,
    totalPages,
  });

  doc.save(getPDFName(tripName));
};

const footerPDF = ({
  pdfDoc,
  leftMargin,
  bottomMargin,
  tripName,
  bodyTextSize,
  footerTextSize,
  currentPage,
  totalPages,
}) => {
  pdfDoc.setFontSize(footerTextSize);
  pdfDoc.text(
    `${tripName} Expenses - Page ${currentPage} of ${totalPages}`,
    leftMargin,
    11 - bottomMargin,
    {
      maxWidth: 8.5 - leftMargin * 2,
    },
  );
  pdfDoc.setFontSize(bodyTextSize);
};

const addExpenseTextToPDF = ({
  pdfDoc,
  data,
  expensesPerPage,
  leftMargin,
  topMargin,
  bottomMargin,
  tripName,
  bodyTextSize,
  footerTextSize,
  startingPage,
  totalPages,
  fuelUnits,
}) => {
  const csv = getCSVData(data);
  const textHeight = 0.25;
  const pageExpenseStartingY = topMargin + textHeight;
  const pageExpenseHeight =
    11 - topMargin - bottomMargin - pageExpenseStartingY;
  const heightPerExpense = pageExpenseHeight / expensesPerPage;

  let currentPage = startingPage;
  let formattedExpenses = [];

  for (let i = 1; i < csv.length; i++) {
    let currentExpense = `Expense ${i}\n`;
    for (let j = 0; j < csv[i].length; j++) {
      if (csv[0][j] === 'Amount') {
        let amount = csv[i][j];
        const numDec = numDecimalPlaces(amount);
        amount = amount.toLocaleString();
        if (numDec === 0) {
          amount += '.00';
        } else if (numDec === 1) {
          amount += '0';
        }
        currentExpense += `${csv[0][j]}: $${amount}\n`;
      } else if (csv[0][j] === 'Fuel Amount' && csv[i][j] !== 'N/A') {
        // WARNING: this cuts off numbers at three decimal places
        currentExpense += `${csv[0][j]}: ${Number.parseFloat(
          csv[i][j],
        ).toLocaleString()} ${fuelUnits}\n`;
      } else {
        currentExpense += `${csv[0][j]}: ${csv[i][j]}\n`;
      }
    }
    formattedExpenses.push(currentExpense);
  }

  for (let i = 0; i < formattedExpenses.length; i++) {
    const textY =
      (i % expensesPerPage) * heightPerExpense + pageExpenseStartingY;
    pdfDoc.text(formattedExpenses[i], leftMargin, textY, {
      maxWidth: 8.5 - leftMargin * 2,
    });

    // We're out of room on this page and there are more expenses.
    if ((i + 1) % expensesPerPage === 0 && i + 1 < formattedExpenses.length) {
      pdfDoc.addPage(pdfOptions);
      currentPage++;
      footerPDF({
        pdfDoc,
        leftMargin,
        bottomMargin,
        tripName,
        bodyTextSize,
        footerTextSize,
        currentPage,
        totalPages,
      });
    }
  }
  return currentPage;
};

const addImagesToPdf = async ({
  pdfDoc,
  images,
  imagesPerPage,
  imageExpenseLabels,
  leftMargin,
  topMargin,
  bottomMargin,
  tripName,
  bodyTextSize,
  footerTextSize,
  startingPage,
  totalPages,
}) => {
  let currentPage = startingPage;
  for (let i = 0; i < images.length; i++) {
    if (i % imagesPerPage === 0) {
      pdfDoc.addPage(pdfOptions);
      currentPage++;
      footerPDF({
        pdfDoc,
        leftMargin,
        bottomMargin,
        tripName,
        bodyTextSize,
        footerTextSize,
        currentPage,
        totalPages,
      });
    }
    const img = images[i];
    const fileExtension = img.path
      .substring(img.path.lastIndexOf('.') + 1)
      .toUpperCase();

    const base64Image = await imageUrlToBase64(img.uri);
    const imageSize = await getImageDimensions(base64Image);
    const isImagePortrait = imageSize.h > imageSize.w;

    const textHeight = 0.25;

    const imageArea = {
      w: 8.5 - leftMargin * 2,
      h: (11 - topMargin - bottomMargin - 0.25) / imagesPerPage - textHeight,
    };
    const isAreaPortrait = imageArea.h > imageArea.w;
    const areaAspectRatio = imageArea.w / imageArea.h;

    let orientedImageWidth =
      isAreaPortrait === isImagePortrait ? imageSize.w : imageSize.h;
    let orientedImageHeight =
      isAreaPortrait === isImagePortrait ? imageSize.h : imageSize.w;
    const orientedImageAspectRatio = orientedImageWidth / orientedImageHeight;

    let imageWidth, imageHeight;
    // Image is more horizontal than its area, so limit the image by the full width of the area.
    if (orientedImageAspectRatio > areaAspectRatio) {
      imageWidth = imageArea.w;
      imageHeight = (imageWidth * orientedImageHeight) / orientedImageWidth;
    }
    // Image is more vertical than its area, so limit the image by the full height of the area.
    else {
      imageHeight = imageArea.h;
      imageWidth = (imageHeight * orientedImageWidth) / orientedImageHeight;
    }

    let imageY = (i % imagesPerPage) * imageArea.h + topMargin + textHeight;
    let labelY = imageY;
    // Compensate for the displacement caused by the rotation.
    if (isAreaPortrait !== isImagePortrait) {
      imageY -= imageWidth;
    }

    pdfDoc.text(`Expense ${imageExpenseLabels[i]}`, leftMargin, labelY - 0.1, {
      maxWidth: 8.5 - leftMargin * 2,
    });
    pdfDoc.addImage(
      base64Image,
      fileExtension,
      leftMargin,
      imageY,
      isAreaPortrait === isImagePortrait ? imageWidth : imageHeight,
      isAreaPortrait === isImagePortrait ? imageHeight : imageWidth,
      '',
      'NONE',
      isAreaPortrait === isImagePortrait ? 0 : -90,
    );
  }
};

const ExportSection = ({ data, tripName, images, fuelUnits, ml = 'auto' }) => (
  <ExportWrapper ml={ml}>
    <Text>Export Expenses</Text>
    <CSVLink filename={getCSVName(tripName)} data={getCSVData(data, fuelUnits)}>
      <Button icon="csv-icon" ml="5px" color="transparent" />
    </CSVLink>
    <Button
      icon="pdf-icon"
      color="transparent"
      onPress={async () => exportPDF(data, images, tripName, fuelUnits)}
    />
  </ExportWrapper>
);

export const AdjustmentExportSection = ({ trip, tripName, ml = '10px' }) => (
  <ExportWrapper ml={ml}>
    <Text>Export Adjustments</Text>
    <Spacer dir="horizontal" size={1} />
    <Text onPress={() => createAdjustmentFiles(trip, tripName)}>
      <Icon name="csv-icon" size={25} />
    </Text>
  </ExportWrapper>
);

export const ItineraryExportSection = ({
  trip,
  tripName,
  companyName,
  ml = '10px',
}) => (
  <ExportWrapper ml={ml}>
    <Box>
      <Box dir="row" ai="center">
        <ItineraryExportText>Itinerary</ItineraryExportText>
        <Spacer dir="horizontal" size={0.2} />
        <Text
          onPress={() =>
            createItineraryFile(trip, tripName, companyName, false)
          }>
          <Icon name="pdf-icon" size={25} />
        </Text>
      </Box>
      {!_.isEmpty(trip?.documents) && (
        <Box dir="row" ai="center">
          <ItineraryExportText>Itinerary + Documents</ItineraryExportText>
          <Spacer dir="horizontal" size={0.2} />
          <Text
            onPress={() =>
              createItineraryFile(trip, tripName, companyName, true)
            }>
            <Icon name="zip-icon" size={25} />
          </Text>
        </Box>
      )}
    </Box>
  </ExportWrapper>
);

const ItineraryExportText = styled(Text)(({ theme }) => ({
  width: theme.layout.space(9),
  textAlign: 'right',
}));

export default ExportSection;
