import React, { useState, useEffect, useCallback } from 'react';
import type { ReactNode } from 'react';
import { View, ScrollView } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import styled from '@emotion/native';
import { useForm } from 'react-hook-form';

import Button from '@appComponents/Button';
import Dialog from '@appComponents/Dialog';
import {
  createAircraft,
  updateAircraft,
  useAircraftData,
} from '@appUtils/aircraft';
import Notification from '@appComponents/Notification';
import { AircraftDetailsForm } from './AircraftDialogForm';
import _ from 'lodash';
import { Timestamp } from '@appFirebase';

type AircraftDialogProps = {
  mode: 'Add' | 'Edit',
  closeDialog: () => {},
  documentPath?: string,
};

export const useAircraftDialog = ({ mode, documentPath }) => {
  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = useCallback(() => setShowDialog(false), []);
  const openDialog = useCallback(() => setShowDialog(true), []);

  // We're intentionally unmounting so the form in the dialog
  // can be reset automatically on next mount
  const dialogNode = showDialog && (
    <AircraftDialog
      mode={mode}
      closeDialog={closeDialog}
      documentPath={documentPath}
    />
  );

  return {
    dialogNode,
    openDialog,
    closeDialog,
  };
};

const AircraftDialog = ({
  mode,
  closeDialog,
  documentPath,
}: AircraftDialogProps): ReactNode => {
  const title = `${mode} Aircraft`;

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState,
    reset,
    watch,
  } = useForm({
    defaultValues: AIRCRAFT_DEFAULTS,
  });

  const loading = useExistingData(documentPath, reset);
  const submitCallback = useSubmitCallback({
    setError,
    closeDialog,
    documentPath,
    dirtyFields: formState.dirtyFields,
  });

  return (
    <DialogLayout
      visible
      title={title}
      onDismiss={closeDialog}
      actionSlot={
        <ButtonContainer>
          <Button ml="auto" color="disabled" onPress={closeDialog}>
            Cancel
          </Button>
          <Button ml={1} color="primary" onPress={handleSubmit(submitCallback)}>
            Save
          </Button>
        </ButtonContainer>
      }>
      <ScrollView contentContainerStyle={scrollContainerStyle}>
        {loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <AircraftDetailsForm control={control} watch={watch} />
        )}

        <Notification
          color="error"
          visible={formState.errors?.general}
          onDismiss={() => clearErrors()}>
          {formState.errors?.general?.message}
        </Notification>
      </ScrollView>
    </DialogLayout>
  );
};

const useExistingData = (documentPath, formReset) => {
  const [existingData, loadingData] = useAircraftData(documentPath);

  useEffect(() => {
    if (!documentPath || loadingData) {
      return;
    }

    formReset(existingData);
  }, [formReset, existingData, documentPath, loadingData]);

  return Boolean(documentPath && loadingData);
};

const useSubmitCallback = ({
  documentPath,
  setError,
  closeDialog,
  dirtyFields,
}) =>
  useCallback(
    payload => {
      const { fuelOff, fuelDate, ...data } = payload;
      const filteredPayload = _.omitBy(data, _.isUndefined);
      if (dirtyFields.fuelOff) {
        filteredPayload.fuelOff = fuelOff;
        filteredPayload.fuelDate = Timestamp.now();
      }
      const task = documentPath
        ? updateAircraft(filteredPayload, documentPath)
        : createAircraft(filteredPayload);

      task
        .then(() => closeDialog())
        .catch(error => {
          setError('general', { type: 'manual', message: error.message });
        });
    },
    [dirtyFields, documentPath, closeDialog, setError],
  );

const AIRCRAFT_DEFAULTS = {
  tailNumber: '',
  name: '',
  type: '',
  numEngines: 1,
  location: '',
  owners: [],
  pilots: [],
  fuelUnits: 'gal',
  meteringSystem: 'Hobbs',
  apuMeteringSystem: 'None',
};

const ButtonContainer = styled(View)(({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  borderTopWidth: 1,
  borderTopColor: theme.colors.border,
  paddingTop: theme.layout.space(1),
  paddingHorizontal: theme.layout.space(1),
}));

const DialogLayout = styled(Dialog)(({ theme }) => ({
  height: theme.layout.height - 0.1 * theme.layout.height,
}));

const scrollContainerStyle = {
  height: '100%',
};

export default AircraftDialog;
